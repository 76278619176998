<template>
  <section class="containerInputList" :class="{'error': isRequiredErrorVisible}">
    <div class="containerInputList__header">
      <label class="containerInputList__label" :class="{'required': required, 'disabled': disabled}">{{ label }}</label>
      <slot name="nextToLabel"></slot>
    </div>
    <genericAutoComplete placeholder="Start typing..."
                         :modelValue="formatedValue"
                         :optionList="optionList"
                         @launchSearch="filterList"
                         ref="autocomplete"
                         :id="id"
                         :disabled="disabled"
                         class="containerInputList__input"
                         :class="{'disabled': disabled, 'noScroll': optionList.length < 7}"
                         @update:modelValue="updateModel($event)"
                         :allowUserValues="allowUserValues"
                         :lenghtToLaunchSearch="lenghtToLaunchSearch">
      <span class="material-icons containerInputList__arrow" @mousedown="showOptions($event)" v-if="showArrowDropDown">arrow_drop_down</span>
    </genericAutoComplete>
    <span class="errorMessage" v-appear="isRequiredErrorVisible">This field cannot be empty</span>
  </section>
</template>

<script>
import { computed, watch, ref, reactive } from 'vue'
import genericAutoComplete from '@/components/autoComplete/genericAutoComplete'

export default {
  name: 'inputList',
  components: {
    genericAutoComplete
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    lenghtToLaunchSearch: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    showLabelFunc: {
      type: Function,
      default: null
    },
    idProperty: {
      type: String,
      required: false
    },
    getAsyncOptions: {
      type: Function,
      default: null
    },
    allowUserValues: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    showArrowDropDown: {
      type: Boolean,
      default: true
    },
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const optionList = ref(props.options)
    const listReference = reactive({})
    const autocomplete = ref(null)

    /**
     * @description Returns a boolean to indicate that this is a required field and it has not a valid value.
     */
    const isRequiredErrorVisible = computed(() => {
      return props.required && props.showErrors && ((typeof props.modelValue === 'object' && props.modelValue) ? !Object.keys(props.modelValue).length : !props.modelValue)
    })

    /**
     * @description Returns a formated value of the model.
     */
    const formatedValue = computed(() => {
      return props.showLabelFunc && props.modelValue ? props.showLabelFunc(props.modelValue) : props.modelValue
    })

    /**
     * @description Checks if the selected option is in the last version of the options.
     * @param {newOptions} array with the updated options.
     */
    watch(() => props.options, (newOptions) => {
      validateCurrentValue(newOptions)
    })

    /**
     * @description Filters the list of options using the given term as filter.
     * @param {term} string to be used as filter.
     */
    function filterList (term) {
      optionList.value = []
      if (term) {
        if (props.getAsyncOptions && term.length >= 3) {
          props.getAsyncOptions(term).then(results => {
            if (props.showLabelFunc) {
              results.forEach(result => {
                optionList.value.push(props.showLabelFunc(result))
                listReference[result[props.idProperty]] = result
              })
            } else {
              optionList.value = results
            }
          })
        } else {
          optionList.value = props.options.filter(option => option.value.toLowerCase().indexOf(term.toLowerCase()) !== -1)
        }
      } else {
        if (!props.getAsyncOptions) {
          optionList.value = props.options
        }
      }
    }

    /**
     * @description Opens the list to show the options.
     * @param {event} event rised by the user.
     */
    function showOptions (event) {
      event.preventDefault()
      autocomplete.value.setFocusOnInput()
    }

    /**
     * @description Emits an event to update the model.
     * @param {option} object with the option selected.
     */
    function updateModel (option) {
      if (props.showLabelFunc && option) {
        emit('update:modelValue', listReference.value[option.id])
      } else {
        emit('update:modelValue', option)
      }
    }

    /**
     * @description Checks if the current option is on the updated list of options, if is not in the list update
     * the model to null.
     * @param {options} array with the list of options.
     */
    function validateCurrentValue (options) {
      if (!props.modelValue) {
        return
      }

      if (typeof props.modelValue === 'string') {
        const option = options.find(option => option.value === props.modelValue)
        if (option) {
          emit('update:modelValue', Object.assign({ value: option.value, id: option.id }, option))
        } else {
          emit('update:modelValue', null)
        }
        return
      }

      const isValuePresent = options.some(option => option.value === props.modelValue.value)

      if (!isValuePresent) {
        emit('update:modelValue', null)
      }
    }

    return {
      optionList,
      listReference,
      autocomplete,
      isRequiredErrorVisible,
      formatedValue,
      filterList,
      showOptions,
      updateModel
    }
  }
}
</script>

<template>
  <section class="supportingFilesContainer">
    <span class="supportingFilesContainer__title">SUPPORTING FILES</span>
    <section class="supportingFilesContainer__dragArea"
             @dragenter="addHoverClass"
             @dragover="stopEvent"
             @dragleave="removeHoverClass"
             @drop="dropHandler"
             :class="{ 'active': hoverIsActive}">
      <section class="supportingFilesContainer__dragArea--main">
        <section class="containerLabels">
          <span :class="uploadIconClass"></span>
          <span class="containerLabels__label">Drop or </span>
          <button class="containerLabels__button" @click="openFileSelector" type="button">Select your Files...</button>
        </section>
      </section>
      <input type="file"
             @change="inputChanged($event)"
             ref="inputFileRef"
             class="supportingFilesContainer__dragArea--input"
             id="supportingFilesUpload"
             multiple/>
    </section>
    <multipleToastMessages :invalidFiles="invalidFiles" @closeToast="removeInvalidFile($event)"></multipleToastMessages>
    <fileUpload v-for="(fileInfo, index) in supportingFiles" :key="index" :itemId="itemId" :fileInfo="fileInfo" @fileDeleted="emit('touchForm')">
    </fileUpload>
    <span v-if="showProgressLabel" class="supportingFilesContainer__progressInformation" :class="{'progress': filesInProgress}">{{ progressInformationLabel }}</span>
  </section>
</template>

<script>
import fileUpload from '@/components/forms/fileUpload/fileUpload'
import fileUploaderConfig from './supportingFiles.json'
import { reactive, ref, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import multipleToastMessages from '@/components/multipleToastMessages/multipleToastMessages'

export default {
  name: 'supportingFiles',
  props: {
    itemId: {
      type: String,
      default: null
    }
  },
  components: {
    fileUpload,
    multipleToastMessages
  },
  emits: ['touchForm'],
  setup (props, { emit }) {
    const store = useStore()
    const hoverIsActive = ref(false)
    const config = fileUploaderConfig
    const counterHover = ref(0)
    const file = reactive({
      name: ''
    })
    const tempId = ref(null)
    const inputFileRef = ref(null)
    const invalidExtensionModalRef = ref(null)
    const invalidFiles = ref([])

    /**
     * @description Get supporting files list
     */
    const supportingFiles = computed(() => {
      return store.state.form.uploadList.filter(uploadFile => uploadFile.multiple)
    })

    /**
     * @description Validate if display progress information Label
     */
    const showProgressLabel = computed(() => {
      const newSupportingFiles = supportingFiles.value.filter(uploadFile => !uploadFile.fileFromEdition)
      return supportingFiles.value.length > 0 && newSupportingFiles.length > 0
    })

    /**
     * @description Validate if there are files in upload progress
     */
    const filesInProgress = computed(() => {
      const uploadingFiles = supportingFiles.value.filter(file => file.status === 'uploading')
      return uploadingFiles.length > 0
    })

    /**
     * @description Progress information Label
     */
    const progressInformationLabel = computed(() => {
      const uploadingFiles = supportingFiles.value.filter(file => file.status === 'uploading')
      return uploadingFiles.length > 0 ? `File${uploadingFiles.length > 1 ? 's' : ''} uploading...` : `File${supportingFiles.value.length > 1 ? 's' : ''} uploaded successfully`
    })

    /**
     * @description Set upload icon class
     */
    const uploadIconClass = computed(() => {
      return supportingFiles.value.length > 0 ? 'grey-icon-upload-cloud-multiple' : 'grey-icon-upload-cloud'
    })

    /**
     * @description Opens the modal to select a file.
     */
    function openFileSelector () {
      inputFileRef.value.click()
    }

    /**
     * @description Adds class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function addHoverClass (event) {
      toggleHoverClass(event, true)
    }

    /**
     * @description Removes class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function removeHoverClass (event) {
      toggleHoverClass(event, false)
    }

    /**
     * @description Toggles precence of hover class to element based on user interaction.
     * @param event event fired by user.
     * @param isClassPresent flag to indicate if hover class should be applied or not.
     */
    function toggleHoverClass (event, isClassPresent) {
      stopEvent(event)
      if (props.disabled) return

      if (isClassPresent) {
        hoverIsActive.value = true
        counterHover.value++
      } else {
        counterHover.value--
        if (counterHover.value === 0) {
          hoverIsActive.value = false
        }
      }
    }

    /**
     * @description Stops propagation of a given event.
     * @param {event} event to stop its propagation.
     */
    function stopEvent (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * @description Manages the drop event to get the file and start to upload process.
     * @param {event} event launches by user's drop action.
     */
    function dropHandler (event) {
      stopEvent(event)
      hoverIsActive.value = false
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        Array.from(event.dataTransfer.files).forEach(file => setFile(file))
      }
    }

    /**
     * @description Handler to read the file from the input.
     * @param event DOM event
     */
    function inputChanged (event) {
      stopEvent(event)
      Array.from(inputFileRef.value.files).forEach(file => setFile(file))
    }

    /**
     * @description Sets the file if is valid.
     */
    function setFile (file) {
      if (!isAValidExtension(file.name)) {
        invalidFiles.value.push(file.name)
        return
      }
      tempId.value = Math.random()
      store.dispatch({
        type: 'form/uploadWorkFile',
        file: file,
        tempId: tempId.value,
        isSupportingFile: true,
        workItemId: props.itemId,
        multiple: true
      })
      nextTick(() => {
        inputFileRef.value.value = ''
      })
    }

    /**
     * @description Validates if a file has a valid extension to be uploaded.
     * @param {fileName} fileName to validate its extesion.
     */
    function isAValidExtension (fileName) {
      const fileNameSplitted = fileName.split('.')
      const extension = fileNameSplitted[fileNameSplitted.length - 1].toLowerCase()
      return config.validExtensions.split(',').some((ext) => ext.toLowerCase() === `.${extension}`)
    }

    /**
     * @description Remove invalid file from list when toast is closed
     * @param file The file to remove
     */
    function removeInvalidFile (file) {
      const fileIndex = invalidFiles.value.indexOf(file)
      if (fileIndex > -1) {
        invalidFiles.value.splice(fileIndex, 1)
      }
    }

    return {
      store,
      config,
      hoverIsActive,
      counterHover,
      file,
      tempId,
      inputFileRef,
      invalidExtensionModalRef,
      openFileSelector,
      supportingFiles,
      showProgressLabel,
      filesInProgress,
      progressInformationLabel,
      addHoverClass,
      removeHoverClass,
      stopEvent,
      dropHandler,
      inputChanged,
      uploadIconClass,
      emit,
      invalidFiles,
      removeInvalidFile
    }
  }
}
</script>

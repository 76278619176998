<template>
  <genericModalLogic :title="title"
                     class="containerFormModal"
                     v-if="isModalVisible"
                     @closeModal="close">
    <p class="containerFormModal__message">
      <span class="containerFormModal__message--message">{{message}}</span>
      <span class="containerFormModal__message--messageStrong">{{boldMessage}}</span>
      <section class="containerFormModal__message--buttons" v-if="deleteConfirmation">
        <button @click.stop="close" class="btnCancel">{{cancelText}}</button>
        <button @click.stop="confirmAction" class="btnConfirm">{{confirmText}}</button>
      </section>
      <section class="containerFormModal__message--buttons" v-else>
        <button @click.stop="close" class="btnCancel">{{cancelText}}</button>
        <button @click.stop="confirmAction" class="btnConfirm">{{confirmText}}</button>
      </section>
    </p>
  </genericModalLogic>
</template>

<script>
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import { ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'formModal',
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    boldMessage: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: null
    },
    cancelText: {
      type: String,
      default: null
    },
    deleteConfirmation: {
      type: Boolean,
      default: false
    }
  },
  components: {
    genericModalLogic
  },
  setup (props, { emit }) {
    const store = useStore()
    const isModalVisible = ref(false)

    /**
     * @description Opens the modal.
     */
    function open () {
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param {event} event fired by the user.
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    /**
     * @description Rises an event to notify users wants to cancel the upload process.
     * @param {event} event fired by the user.
     */
    function confirmAction (event) {
      event.preventDefault()
      if (props.title === 'Exit File Upload') {
        const supportingFilesUploading = store.state.form.uploadList.filter(uploadFile => uploadFile.multiple && uploadFile.status === 'uploading')
        if (supportingFilesUploading.length > 0) {
          const promisesToResolveBeforeExit = []
          supportingFilesUploading.forEach(supportingFile => {
            promisesToResolveBeforeExit.push(store.dispatch({
              type: 'form/removeWorkFile',
              tempId: supportingFile.tempId,
              workItemId: supportingFile.workItemId,
              isEdition: false,
              isSupportingFile: true,
              multiple: true
            }))
          })
          Promise.all(promisesToResolveBeforeExit).then(() => {
            emit('confirmAction')
            isModalVisible.value = false
          })
        } else {
          emit('confirmAction')
          isModalVisible.value = false
        }
      } else {
        emit('confirmAction')
        isModalVisible.value = false
      }
    }

    return {
      isModalVisible,
      open,
      close,
      confirmAction
    }
  }
}
</script>

<template>
  <section class="containerInputGeneric" :class="{'error': isRequiredErrorVisible || forceErrorClass || isMaxlengthError}">
    <label class="containerInputGeneric__label" :class="{'required': required, 'disabled': disabled, forceErrorClass: 'required'}">{{ label }}</label>
    <textarea class="containerInputGeneric__input multilineal"
              @input="updateInput"
              ref="inputValue"
              :id="id"
              :value="modelValue"
              :maxLength="maxLength"
              :minLength="minLength"
              :name="randomName"
              :class="{'disabled': disabled}"
              v-if="multilineal"/>
    <input class="containerInputGeneric__input"
          type="text"
          @input="updateInput"
          ref="inputValue"
          :id="id"
          :value="modelValue"
          :name="randomName"
          :maxLength="maxLength"
          :minLength="minLength"
          :class="{'disabled': disabled}"
          v-else/>
    <section class="containerInputGeneric__messages" :class="{'showCounter': showCounter}">
      <span class="containerInputGeneric__messages_label validationMessage errorMessage" v-appear="isRequiredErrorVisible">This field cannot be empty</span>
      <span :class="{'errorMessage': forceErrorClass || isMaxlengthError}" class="containerInputGeneric__messages_label validationText" v-if="maxLength || minLength">
        <slot>
          <div v-if="maxLength">
            <span v-if="charactersCounter > 0"> {{maxLength - charactersCounter}} characters left</span>
            <span v-else>Max {{maxLength}} characters</span>
          </div>
          <span v-else-if="minLength > 0">Min {{minLength}} characters</span>
        </slot>
      </span>
      <span v-if="showCounter" class="containerInputGeneric__messages_label validationText" :class="{'errorMessage': forceErrorClass || isMaxlengthError}">
        <span>{{charactersCounter}} characters</span>
      </span>
    </section>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  name: 'inputGeneric',
  props: {
    label: {
      required: true,
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    minLength: {
      type: Number,
      default: null
    },
    forceErrorClass: {
      type: Boolean,
      default: false
    },
    multilineal: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup (props, { slots, emit }) {
    const inputValue = ref(null)
    const randomName = Math.random()

    /**
     * @description Returns a boolean to indicate that this is a required field and it has not a valid value.
     */
    const isRequiredErrorVisible = computed(() => {
      return props.required && props.showErrors && !props.modelValue
    })

    /**
     * @description Returns a boolean to indicate that input has more chars than allowed.
     */
    const isMaxlengthError = computed(() => {
      return props.maxLength && props.maxLength > 0 && props.modelValue && props.modelValue.length > props.maxLength
    })

    /**
     * @description Returns a number to indicate the length of the imput.
     */
    const charactersCounter = computed(() => {
      return props.modelValue ? props.modelValue.length : 0
    })

    /**
     * @description Emits the value sets by the user in the input.
     */
    function updateInput () {
      const valueToEmit = inputValue.value.value && inputValue.value.value.trim() ? inputValue.value.value : null
      emit('update:modelValue', valueToEmit)
    }

    return {
      inputValue,
      isRequiredErrorVisible,
      updateInput,
      isMaxlengthError,
      randomName,
      charactersCounter
    }
  }
}
</script>

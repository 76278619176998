<template>
  <section class="containerThumbnailUploader">
    <section class="containerThumbnailUploader__dragArea"
      @dragenter="addHoverClass"
      @dragover="stopEvent"
      @dragleave="removeHoverClass"
      @drop="dropHandler"
      :class="{ active: hoverIsActive}"
      v-if="!previewImageLoaded">

      <section class="containerThumbnailUploader__dragArea--main">
        <section class="containerLabels">
          <span class="grey-icon-upload-cloud"></span>
          <span class="containerLabels__label">Drop or</span>
          <button class="containerLabels__button" @click="openFileSelector" type="button">Select your File...</button>
        </section>
      </section>
      <input type="file"
        @change="inputChanged($event)"
        ref="inputFileRef"
        class="containerThumbnailUploader__dragArea--input"
        accept=".jpg, .jpeg, .png"
        id="containerUpload"/>
    </section>
    <section v-if="previewImageLoaded" class="containerThumbnailUploader__fileTempPreview">
      <img v-if="thumbnailUrl" :src="thumbnailUrl" class="containerThumbnailUploader__fileTempPreview--image">
      <img v-else-if="tempImage" :src="tempImage.src" class="containerThumbnailUploader__fileTempPreview--image">
    </section>
    <section v-if="previewImageLoaded" class="containerThumbnailUploader__fileInfo" :class="{'failed': failedStatus}">
      <span class="containerThumbnailUploader__fileInfo--label" v-if="displayPercentageLabel">{{percentageLabel}}</span>
      <span class="containerThumbnailUploader__fileInfo--label" v-if="failedStatus">Upload failed</span>
      <span class="containerThumbnailUploader__fileInfo--remove" v-if="failedStatus || completedStatus" @click="openDeleteConfirmationModal">Remove</span>
    </section>
    <invalidExtensionModal ref="invalidExtensionModalRef"/>
    <formModal ref="deleteThumbnailModalRef"
      class="deleteConfirmation"
      title="Delete Thumbnail"
      message="Are you sure you want to delete this thumbnail?"
      confirmText="DELETE"
      cancelText="CANCEL"
      deleteConfirmation
      @confirmAction="deleteThumbnail"/>
  </section>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import invalidExtensionModal from '@/components/forms/invalidExtensionModal/invalidExtensionModal'
import formModal from '@/components/forms/formModal/formModal'
export default {
  name: 'thumbnailUploader',
  props: {
    itemId: {
      type: String,
      required: true
    },
    thumbnailUrl: {
      type: String,
      default: null
    },
    source: {
      type: String,
      required: true
    }
  },
  components: {
    invalidExtensionModal,
    formModal
  },
  emits: ['setValidationActive', 'resetThumbnailUrl', 'touchForm'],
  setup (props, { emit }) {
    const store = useStore()
    const hoverIsActive = ref(false)
    const counterHover = ref(0)
    const file = reactive({
      name: ''
    })
    const inputFileRef = ref(null)
    const invalidExtensionModalRef = ref(null)
    const tempImage = ref(null)
    const previewImageLoaded = ref(props.thumbnailUrl)
    const fileImage = ref(false)
    const deleteThumbnailModalRef = ref(null)
    const newThumbnailUrl = ref('')

    /**
     * @description Get percentage label.
     */
    const percentageLabel = computed(() => {
      return completedStatus.value ? store.state.form.thumbnailInfo.size : `${store.state.form.thumbnailInfo.progress}% of ${store.state.form.thumbnailInfo.size}`
    })

    /**
     * @description Validate if file status is uploading.
     */
    const uploadingStatus = computed(() => {
      return store.state.form.thumbnailInfo.status === 'uploading'
    })

    /**
     * @description Validate if file status is completed.
     */
    const completedStatus = computed(() => {
      return store.state.form.thumbnailInfo.status === 'completed'
    })

    /**
     * @description Validate if file status is failed.
     */
    const failedStatus = computed(() => {
      return store.state.form.thumbnailInfo.status === 'failed'
    })

    /**
     * @description Validate if display percentage label.
     */
    const displayPercentageLabel = computed(() => {
      return uploadingStatus.value || (completedStatus.value && !store.state.form.thumbnailInfo.edition)
    })

    /**
     * @description Opens the modal to select a file.
     */
    function openFileSelector () {
      inputFileRef.value.click()
    }

    /**
     * @description Adds class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function addHoverClass (event) {
      toggleHoverClass(event, true)
    }

    /**
     * @description Removes class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function removeHoverClass (event) {
      toggleHoverClass(event, false)
    }

    /**
     * @description Toggles precence of hover class to element based on user interaction.
     * @param event event fired by user.
     * @param isClassPresent flag to indicate if hover class should be applied or not.
     */
    function toggleHoverClass (event, isClassPresent) {
      stopEvent(event)

      if (isClassPresent) {
        hoverIsActive.value = true
        counterHover.value++
      } else {
        counterHover.value--
        if (counterHover.value === 0) {
          hoverIsActive.value = false
        }
      }
    }

    /**
     * @description Stops propagation of a given event.
     * @param {event} event to stop its propagation.
     */
    function stopEvent (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * @description Manages the drop event to get the file and start to upload process.
     * @param {event} event launches by user's drop action.
     */
    function dropHandler (event) {
      stopEvent(event)

      hoverIsActive.value = false

      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        setFile(event.dataTransfer.files[0])
      }
    }

    /**
     * @description Handler to read the file from the input.
     * @param event DOM event
     */
    function inputChanged (event) {
      stopEvent(event)
      setFile(inputFileRef.value.files[0])
    }

    /**
     * @description Sets the file if is valid.
     * @param file file variable
     */
    function setFile (file) {
      const fileExt = fileExtension(file.name)
      if (!['.jpg', '.jpeg', '.png'].some((ext) => ext.toLowerCase() === fileExt)) {
        invalidExtensionModalRef.value.open()
        return
      }
      const reader = new FileReader()
      const image = new Image()

      readImage(file, reader, image).then(
        () => {
          getImage(image).then(
            (width, height) => {
              if (width < 800 || height < 800) {
                emit('setValidationActive', true)
                return
              }
              emit('setValidationActive', false)
              emit('touchForm')
              uploadSelectedImage(fileExt, file)
            }
          )
        }
      )
    }

    /**
     * @description Get dimensions of image
     * @param image object of JS
     * @returns {Promise} resolve promise
     */
    function getImage (image) {
      return new Promise((resolve, reject) => {
        image.onload = () => {
          const { width, height } = image
          resolve(width, height)
        }
      })
    }

    /**
     * @description Upload selected image after validations success
     * @param fileExt File extension
     * @param file uploaded file
     */
    function uploadSelectedImage (fileExt, file) {
      setTempPlaceholder(file)
      store.commit({
        type: 'form/SET_THUMBNAIL_INFO',
        name: file.name,
        size: file.size,
        extension: fileExt,
        status: 'uploading'
      })
      store.dispatch({
        type: 'form/uploadThumbnail',
        itemId: props.itemId,
        file: file,
        source: props.source,
        origin: 'upload'
      }).then((response) => {
        newThumbnailUrl.value = response.data
      })
    }

    /**
     * @description Set temporal placeholder for file uploader component
     * @param file selected file
     */
    function setTempPlaceholder (file) {
      const reader = new FileReader()
      tempImage.value = new Image()
      readImage(file, reader).then(
        () => {
          fileImage.value = true
          previewImageLoaded.value = true
        }
      )
    }

    /**
     * @description Get file extension
     * @param fileName name of the file
     */
    function fileExtension (fileName) {
      const fileNameSplitted = fileName.split('.')
      return `.${fileNameSplitted[fileNameSplitted.length - 1].toLowerCase()}`
    }

    /**
     * @description Read File (Image)
     * @param file uploaded
     * @param reader object of JS
     * @param image object of JS
     * @returns {Promise} resolve promise
     */
    function readImage (file, reader, image = tempImage.value) {
      return new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
          image.src = reader.result
          resolve()
        }
        reader.readAsDataURL(file)
      })
    }

    /**
     * @description Open delete comfirmation modal
     */
    function openDeleteConfirmationModal () {
      deleteThumbnailModalRef.value.open()
    }

    /**
     * @description Delete uploaded thumbnail.
     */
    function deleteThumbnail () {
      if (completedStatus.value) {
        store.dispatch({
          type: 'form/deleteThumbnail',
          itemId: props.itemId,
          source: props.source,
          thumbnailUrl: props.thumbnailUrl || newThumbnailUrl.value
        }).then(() => {
          inputFileRef.value = null
          fileImage.value = false
          tempImage.value = null
          previewImageLoaded.value = false
          emit('touchForm')
          emit('resetThumbnailUrl')
        })
      }
    }

    return {
      store,
      hoverIsActive,
      counterHover,
      file,
      inputFileRef,
      invalidExtensionModalRef,
      previewImageLoaded,
      tempImage,
      fileImage,
      percentageLabel,
      uploadingStatus,
      completedStatus,
      failedStatus,
      openFileSelector,
      addHoverClass,
      removeHoverClass,
      toggleHoverClass,
      stopEvent,
      dropHandler,
      inputChanged,
      deleteThumbnail,
      displayPercentageLabel,
      deleteThumbnailModalRef,
      openDeleteConfirmationModal
    }
  }
}
</script>

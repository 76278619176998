<template>
  <section class="containerIntelForm" ref="intelFormRef">
    <inputListMultipleValues label="TAGS" v-model:modelValue="form.tags" id="form_tags" class="containerWorkForm__tags" :getAsyncOptions="searchTags" idProperty="id" :showLabelFunc="(option) =>  ({ value: `${option.value}`, id: option.id})" allowUserValues :showArrowDropDown="false"/>
    <inputListMultipleValues label="COLLABORATORS" v-model:modelValue="form.collaborators" :getAsyncOptions="searchPeople" :showLabelFunc="(option) =>  ({ value: `${option.name} (${option.email})`, id: option.email })" idProperty="email" id="workForm_collaborators" :showArrowDropDown="false"/>
    <genericCheckBox v-model="form.isPublicFile" class="containerIntelForm__permissionCheck" @change="changeHandler">
      <span class="containerIntelForm__permissionCheck--label">Allow public access to the file</span>
    </genericCheckBox>
  </section>
</template>

<script>
import inputListMultipleValues from '@/components/forms/inputListMultipleValues/inputListMultipleValues'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import { useStore } from 'vuex'
import { inject, ref } from 'vue'

export default {
  name: 'intelForm',
  components: {
    inputListMultipleValues,
    genericCheckBox
  },
  setup (props) {
    const store = useStore()
    const form = inject('form')
    const intelFormRef = ref(null)

    /**
     * @description Searches people based on search term.
     * @param {searchTerm} searchTerm Term to search people.
     */
    function searchPeople (searchTerm) {
      return store.dispatch({
        type: 'form/searchUser',
        searchTerm: searchTerm
      })
    }

    /**
     * @description Searches the tags based on a search term.
     * @param {searchTerm} searchTerm Term to search tags.
     */
    function searchTags (searchTerm) {
      return store.dispatch({
        type: 'form/searchTag',
        searchTerm: searchTerm
      })
    }

    /**
     * @description Emits an event to notify the component has changed.
     */
    function changeHandler () {
      intelFormRef.value.dispatchEvent(new Event('change', { bubbles: true }))
    }

    return {
      intelFormRef,
      searchPeople,
      searchTags,
      changeHandler,
      form
    }
  }
}
</script>

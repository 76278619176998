<template>
  <section class="containerNotSupportedMobile">
    <img :src="require(`@/assets/not_mobile_supported.svg`)"/>
    <p class="containerNotSupportedMobile__redMessage">{{message}}</p>
    <p class="containerNotSupportedMobile__message">Please use a computer or a tablet for a better experience.</p>
  </section>
</template>

<script>

export default {
  name: 'notSupportedMobile',
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

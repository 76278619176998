<template>
  <section class="containerSourceSelector">
    <span class="containerSourceSelector__label">THIS IS THE SOURCE TO UPLOAD THE FILE</span>
    <section class="containerSourceSelector__buttons" :class="{'disabled': disabled, 'edition': isAnEdition}">
      <button v-for="(source, index) in sources" :key="index"
              :title="source.tooltip"
              class="containerSourceSelector__buttons--button"
              :class="{'selected': isSelected(source.label), 'buttonDisabled': buttonDisabled(source.label)}"
              @click="setSource(source.label)" type="button" >
        <span :class="[source.icon, 'icon']"></span>
        <section class="containerSourceLabel">
          <span class="containerSourceLabel__source">{{ source.label }}</span>
        </section>
      </button>
    </section>
  </section>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'sourceSelector',
  props: {
    value: {
      type: String
    },
    sources: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    },
    isAnEdition: {
      type: Boolean,
      default: false
    },
    contentType: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    /**
     * @description Returns a flag to indicate if a source is selected.
     */
    const isSelected = computed(() => label => label === props.value)

    /**
     * @description Validate if content type is 5things
     */
    const buttonDisabled = computed(() => (label) => {
      return props.contentType && Object.keys(props.contentType).length > 0 && label === 'work' && props.contentType.value === '5Things'
    })

    /**
     * @description Sets the source selected by the user.
     * @param {string} source Source selected by the user.
     */
    function setSource (source) {
      emit('input', source)
    }

    return {
      isSelected,
      setSource,
      buttonDisabled
    }
  }
}
</script>

<template>
  <section class="containerUploadFile">
    <a class="containerUploadFile__backBtn" @click.stop.prevent="goBackHandler">
      <span class="grey-icon-arrow-back"></span>
      <span class="containerUploadFile__backLabel">Back</span>
    </a>
    <notSupportedMobile v-show="store.state.isMobileResolution" message='This device does not allow file uploading.'></notSupportedMobile>
    <section v-show="!store.state.isMobileResolution">
      <div v-if ="isDataLoading" class="containerUploadFile__titleSkeleton"></div>
      <h1 v-else class="containerUploadFile__title">{{mainLabel}}</h1>
      <section class="containerUploadFile__main">
        <skeletonUploadFile v-if="isDataLoading"></skeletonUploadFile>
        <genericForm v-show="!isDataLoading" @touchedForm="setformAsTouched" ref="genericFormRef" :itemData="itemData" @goBack="goBackHandler($event)" @resetThumbnailUrl="itemData.thumbnailUrl = null"></genericForm>
      </section>
    </section>
    <formModal
      ref="formModalRef"
      title="Exit File Upload"
      message="If you exit now you will lose the information. "
      boldMessage="Do you want to exit?"
      confirmText="YES"
      cancelText="CANCEL"
      @confirmAction="goBack"
    />
  </section>
</template>

<script>
import genericForm from '@/components/forms/genericForm/genericForm'
import formModal from '@/components/forms/formModal/formModal'
import skeletonUploadFile from '@/components/skeletons/skeletonUploadFile/skeletonUploadFile'
import notSupportedMobile from '@/components/notSupportedMobile/notSupportedMobile'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'uploadFile',
  components: {
    genericForm,
    formModal,
    skeletonUploadFile,
    notSupportedMobile
  },
  setup () {
    const store = useStore()
    const isFormTouched = ref(false)
    const prevRoute = ref(null)
    const itemData = ref({})
    const isEdition = ref(false)
    const formModalRef = ref(null)
    const genericFormRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const isDataLoading = ref(true)

    /**
     * @description Returns the label title of the page.
     */
    const mainLabel = computed(() => {
      return isEdition.value ? 'Edit a File' : 'Upload a File'
    })

    getInitialData()

    /**
     * @description Loads all the initial data.
     */
    function getInitialData () {
      const promisesToResolveBeforeEnter = [store.dispatch({ type: 'form/getCommonMetadata' })]
      let source = null
      let itemId = null
      const paramIdFromURL = route.params.itemId
      if (paramIdFromURL) {
        [source, itemId] = paramIdFromURL.toLowerCase().split('_')

        if (!['work', 'intelligence'].find(validSource => validSource === source.toLowerCase())) {
          router.push({ name: 'uploadFile' })
          isDataLoading.value = false
          return
        }

        promisesToResolveBeforeEnter.push(
          store.dispatch({
            type: source === 'work' ? 'form/getWorkItem' : 'form/getIntelItem',
            id: source === 'work' ? paramIdFromURL : itemId
          })
        )
      }

      if (source !== 'intelligence') {
        promisesToResolveBeforeEnter.push(
          store.dispatch({ type: 'form/getWorkMetadata' })
        )
      }

      if (document && document.fonts && document.fonts.load) {
        promisesToResolveBeforeEnter.push(document.fonts.load('12px Circular Std Medium'))
        promisesToResolveBeforeEnter.push(document.fonts.load('12px Roboto'))
        promisesToResolveBeforeEnter.push(document.fonts.load('12px Material Icons'))
        promisesToResolveBeforeEnter.push(document.fonts.load('12px Circular Std Bold'))
      }

      Promise.all(promisesToResolveBeforeEnter).then(responses => {
        store.commit({
          type: 'form/SET_DEFAULT_VALUES',
          defaultValues: responses[0].defaultValues
        })
        store.commit({
          type: 'form/SET_DATA_READY',
          isDataReady: true
        })

        if (source && responses[1]) {
          isEdition.value = true
          const fileData = source === 'work' ? responses[1].assets[0] : responses[1]
          const fileProcessed = fileData.statusVideo && fileData.statusVideo === 'Processed'
          const thumbnailOrigin = source === 'work' ? fileData.thumbnailLastState : fileData.thumbnailOrigin
          store.commit({
            type: 'form/SET_SELECT_THUMBNAIL_INFO',
            fileProcessed: fileProcessed,
            fileInformation: fileProcessed ? { permanentSasUrl: fileData.permanentSasUrl, thumbnails: fileData.generatedThumbnails } : {}
          })
          let selectionType = ''
          switch (thumbnailOrigin) {
            case 'Autogenerated':
            case 'autogenerated':
              selectionType = 'autoGenerated'
              break
            case 'Placeholder':
              selectionType = 'upload'
              break
            default:
              selectionType = thumbnailOrigin ? thumbnailOrigin.toLowerCase() : thumbnailOrigin
              break
          }
          store.commit({
            type: 'form/SET_THUMBNAIL_SELECTED',
            selectionType: selectionType,
            selectedImage: fileData.thumbnailUrl
          })
          if (source === 'work') {
            responses[1].thumbnailUrl = responses[1].assets[0].thumbnailUrl
            responses[1].supportingFiles.forEach(supportingFile => {
              store.commit({
                type: 'form/ADD_FILE_TO_UPLOAD_LIST',
                file: { name: supportingFile.originalName, size: supportingFile.size },
                source: 'work',
                tempId: Math.random(),
                status: 'completed',
                progress: 100,
                multiple: true,
                fileFromEdition: true,
                supportingFileId: supportingFile.id
              })
            })
          }
          store.commit({
            type: 'form/SET_TRANSACTION_ID',
            transactionId: responses[1].transactionId
          })
          if (responses[1].thumbnailUrl) {
            store.commit({
              type: 'form/SET_THUMBNAIL_INFO',
              status: 'completed',
              edition: true
            })
          }
          itemData.value = { source: source, ...responses[1] }
        }
        isDataLoading.value = false
      }).catch((err) => {
        if (err.response.status) {
          router.push({ name: 'uploadFile' })
          isDataLoading.value = false
        }
      })
    }

    /**
     * @description Sets form as touched.
     */
    function setformAsTouched () {
      if (!isFormTouched.value) {
        genericFormRef.value.manageBeforeUnloadListener(false)
        genericFormRef.value.manageBeforeUnloadListener()
        isFormTouched.value = true
      }
    }

    /**
     * @description Determines if user can go back or if a modal should be shown.
     * @param event event data
     */
    function goBackHandler (event) {
      if (isFormTouched.value && event !== 'uploaded') {
        formModalRef.value.open()
      } else {
        const supportingFilesUploading = store.state.form.uploadList.filter(uploadFile => uploadFile.multiple && uploadFile.status === 'uploading')
        if (supportingFilesUploading.length > 0) {
          formModalRef.value.open()
        } else {
          goBack()
        }
      }
    }

    /**
     * @description Sends the user to previous page.
     */
    function goBack () {
      if (!store.state.isMobileResolution) genericFormRef.value.manageBeforeUnloadListener(false)
      if (document.referrer) {
        store.commit({
          type: 'form/CLEAR_UPLOAD_FILES'
        })
        if (document.referrer.includes('/detail/') || document.referrer.includes('/detailWork/')) {
          window.open(document.referrer, '_self')
        } else {
          const prevPage = window.location.href
          window.history.go(-1)
          setTimeout(function () {
            if (window.location.href === prevPage) {
              router.push({ name: 'home' })
            }
          }, 500)
        }
        document.querySelector('#app #app').scrollTo(0, 0)
      } else {
        window.location.replace((itemData.value && Object.keys(itemData.value).length) ? '/' : `${process.env.VUE_APP_PF_URL}myUploads`)
      }
    }

    return {
      setformAsTouched,
      prevRoute,
      goBackHandler,
      goBack,
      genericFormRef,
      formModalRef,
      isEdition,
      itemData,
      mainLabel,
      store,
      isDataLoading
    }
  }
}
</script>

<template>
  <form class="containerForm" :class="{edition: isAnEdition, 'disabled': disableFields}" @change="changeHandler">
    <inputList label="CONTENT"
               v-model:modelValue="form.contentType"
               :options="contentTypeOptions"
               id="form_content"
               required
               :showErrors="showErrors"
               @update:modelValue="suggestSource"/>
    <sourceSelector :value="form.source"
                    :sources="config.sources"
                    :disabled="isSourceDisabled"
                    :isAnEdition="isAnEdition"
                    :contentType="form.contentType"
                    class="containerForm__file"
                    @input="setSource"/>
    <h2 class="containerForm__title">ATTACH YOUR FILE <span class="grey-icon-upload-black"></span></h2>
    <fileUploader class="containerForm__fileUploader"
                  ref="mainFileUploaderRef"
                  :source="form.source"
                  :disabled="isFileUploaderDisabled"
                  :isAnEdition="isAnEdition"
                  :fileAlreadyuploaded="file"
                  required
                  @updateFileInfo="setFileInfo"
                  :showErrors="showErrors"
                  :contentType="form.contentType"/>
    <section v-show="file && file.id">
      <section class="containerForm__thumbnailUploader" v-if="file && file.id">
        <span v-if="!showThumbnailSelector" class="containerForm__thumbnailUploader--label">ADD THUMBNAIL (Optional)</span>
        <section v-if="!showThumbnailSelector" class="containerForm__thumbnailUploader--uploader">
          <thumbnailUploader
            class="uploaderContainer"
            :itemId="file.id"
            :thumbnailUrl="setThumbnailUrl"
            :source="form.source"
            @setValidationActive="setValidationActive"
            @resetThumbnailUrl="resetThumbnailUrl"
            @touchForm="$emit('touchedForm', true)"
          >
          </thumbnailUploader>
          <div class="message">
            <p>Upload thumbnail that reflects your publication content in the application. Remember that only .png and .jpg format are allowed. <span class="message__attention"> This will call the user's attention.</span></p>
            <p class="message__validation" :class="{'validationActive': validationActive}">Minimum resolution: 800px x 800px</p>
          </div>
        </section>
        <selectThumbnail
          v-if="showThumbnailSelector"
          :itemId="file.id"
          :source="form.source"
          @touchForm="$emit('touchedForm', true)">
        </selectThumbnail>
        <supportingFiles v-if="form.source === 'work'" :itemId="file.id" @touchForm="$emit('touchedForm', true)">
        </supportingFiles>
      </section>
      <h2 class="containerForm__titleInfo">FILE INFORMATION</h2>
      <inputGeneric label="TITLE"
                    v-model:modelValue="form.title"
                    id="form_title"
                    required
                    :showErrors="showErrors"
                    :maxLength=50 />
      <inputGeneric label="DESCRIPTION"
                    v-model:modelValue="form.description"
                    :forceErrorClass="showErrors && form.submitToGreyCom && form.submitToGreyCom.submitForGreyCom && form.description && form.description.length < 500"
                    required
                    id="form_description"
                    class="containerForm__description"
                    :key="form.source"
                    :maxLength="maxDescriptionLength"
                    multilineal
                    :showErrors="showErrors"
                    :minLength="minDescriptionLength"
                    :showCounter="form.source === 'work'">
        <template v-if="form.source === 'work'">
          To be submitted for Grey.com, the description must have at least {{minDescriptionLength}} characters.
        </template>
      </inputGeneric>
      <section class="containerForm__twoColumns">
        <inputList :label="form.source === 'work' ? 'YEAR CREATED' : 'PUBLISH YEAR'"
                  v-model:modelValue="form.year"
                  :options="store.state.form.metadata.years"
                  id="form_year"
                  :showErrors="showErrors"
                  required/>
        <inputList label="COUNTRY"
                  v-model:modelValue="form.country"
                  :options="store.state.form.metadata.countries"
                  :showErrors="showErrors"
                  id="form_country"
                  required/>
        <inputList label="CLIENT"
                  v-model:modelValue="form.client"
                  :options="store.state.form.metadata.clients"
                  id="form_client"
                  :showErrors="showErrors"
                  required/>
        <inputList label="BRAND"
                  v-model:modelValue="form.brand"
                  :options="form.client && form.client.children ? form.client.children: []"
                  id="form_brand"
                  :showErrors="showErrors"
                  required/>
      </section>
      <inputListMultipleValues label="REGION"
                                v-model:modelValue="form.region"
                                :key="regionKey"
                                @update:modelValue="regionKey = Math.random()"
                                :options="store.state.form.metadata.regions"
                                idProperty="id"
                                :showLabelFunc="(option) => option"
                                id="form_region"
                                :showErrors="showErrors"
                                required/>
      <inputListMultipleValues label="AGENCY/OFFICE"
                                v-model:modelValue="form.agency"
                                :key="agencyOfficeKey"
                                @update:modelValue="agencyOfficeKey = Math.random()"
                                :options="store.state.form.metadata.agencies"
                                idProperty="id"
                                :showLabelFunc="(option) => option"
                                id="form_agency"
                                :showErrors="showErrors"
                                required/>
      <inputList label="SOURCE/AUTHOR"
                  v-model:modelValue="form.author"
                  :getAsyncOptions="searchPeople"
                  :showArrowDropDown="false"
                  id="form_author"
                  required
                  :showErrors="showErrors"
                  class="containerForm__author"/>
      <inputListMultipleValues label="MEDIA"
                                v-model:modelValue="form.media"
                                :options="store.state.form.metadata.media"
                                idProperty="id"
                                :showLabelFunc="(option) => option"
                                id="form_media"
                                :showErrors="showErrors"
                                required/>
      <inputTree label="INDUSTRY/CATEGORY"
                ref="industryCategoryRef"
                v-if="store.state.form.isDataReady"
                :initialTree="store.state.form.metadata.industries"
                v-model:modelValue="form.industries"
                :showErrors="showErrors"
                separator=' : '
                required
                id="form_industries"/>
      <intelForm v-if="form.source === 'intelligence'" :form="form"/>
      <workForm v-if="form.source === 'work'" :form="form" :showErrors="showErrors" :fileId="file && file.id" @openAwardModal="toggleAwardModal"/>
      <section class="containerForm__buttons">
        <section class="containerForm__buttons--containerButtons">
          <button type="button" class="btnCancel" @click.stop.prevent="cancelEdition" v-if="isAnEdition">CANCEL</button>
          <button type="button" class="btnSubmit" @click.stop.prevent="publishForm" :class="{'withSpinner': showSpinner}">
            <spinner class="publishSpinner" v-if="showSpinner"/>
            {{publishButtonLabel}}
          </button>
        </section>
        <label v-if="isButtonErrorMessageVisible" class="errorMessage">{{validationErrorMessageLabel}}</label>
      </section>
    </section>
    <formModal ref="changeSourceModalRef"
               title="Change Content"
               message="Changing content will change the source"
               confirmText="CHANGE"
               cancelText="CANCEL"
               class="changeSourceModal"
               :deleteConfirmation="true"
               @confirmAction="setSource(selectedSource, false)"/>
    <awardModal v-if="awardModalOpen" @changeHandler="changeHandler" @closeModal="toggleAwardModal(false)" />
    <fileUploadedModal ref="successPopUpRef" @close="goToPrevPage" :isAnEdition="isAnEdition"/>
  </form>
</template>

<script>
import inputList from '@/components/forms/inputList/inputList'
import inputGeneric from '@/components/forms/inputGeneric/inputGeneric'
import genericFormConfig from './genericForm.json'
import sourceSelector from '@/components/forms/sourceSelector/sourceSelector'
import fileUploader from '@/components/forms/fileUploader/fileUploader'
import inputTree from '@/components/forms/inputTree/inputTree'
import intelForm from '@/components/forms/intelForm/intelForm'
import workForm from '@/components/forms/workForm/workForm'
import inputListMultipleValues from '@/components/forms/inputListMultipleValues/inputListMultipleValues'
import formModal from '@/components/forms/formModal/formModal'
import awardModal from '@/components/forms/awardModal/awardModal'
import fileUploadedModal from '@/components/forms/fileUploadedModal/fileUploadedModal'
import thumbnailUploader from '@/components/forms/thumbnailUploader/thumbnailUploader'
import supportingFiles from '@/components/forms/supportingFiles/supportingFiles'
import selectThumbnail from '@/components/forms/selectThumbnail/selectThumbnail'
import spinner from '@/components/spinner/spinner'
import { useStore } from 'vuex'
import { computed, nextTick, provide, ref, watch } from 'vue'

export default {
  name: 'genericForm',
  components: {
    inputList,
    sourceSelector,
    inputGeneric,
    fileUploader,
    inputTree,
    intelForm,
    workForm,
    inputListMultipleValues,
    formModal,
    awardModal,
    fileUploadedModal,
    thumbnailUploader,
    supportingFiles,
    selectThumbnail,
    spinner
  },
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['touchedForm', 'goBack', 'resetThumbnailUrl'],
  setup (props, { emit }) {
    const store = useStore()
    const form = ref(JSON.parse(JSON.stringify(genericFormConfig.formData.commonFields)))
    const showErrors = ref(false)
    const config = ref(genericFormConfig)
    const hasAllMandatoryField = ref(false)
    const isFormTouched = ref(false)
    const file = ref(null)
    const lastTypeUploaded = ref(null)
    const selectedSource = ref(null)
    const isFileUploadedOnce = ref(false)
    const isEditionDataLoaded = ref(false)
    const changeSourceModalRef = ref(false)
    const successPopUpRef = ref(null)
    const awardModalOpen = ref(false)
    const validationActive = ref(false)
    const regionKey = ref(Math.random())
    const agencyOfficeKey = ref(Math.random())
    const disableFields = ref(false)
    const mainFileUploaderRef = ref(null)
    const industryCategoryRef = ref(null)
    const showSpinner = ref(false)

    provide('form', form)

    /**
     * @description Flag to indicate if content type will be disabled.
     */
    const isContentTypeDisabled = computed(() => {
      return Boolean(file.value && Object.keys(file.value).length) || isAnEdition.value
    })

    /**
     * @description Flag to indicate if source will be disabled.
     */
    const isSourceDisabled = computed(() => {
      return !(form.value.contentType && form.value.contentType.value) || isContentTypeDisabled.value
    })

    /**
     * @description Flag to indicate if file uploader will be disabled.
     */
    const isFileUploaderDisabled = computed(() => {
      return !(form.value.contentType && form.value.contentType.value) || !form.value.source || isAnEdition.value
    })

    /**
     * @descritption Number of max chars for description.
     */
    const maxDescriptionLength = computed(() => {
      return form.value.source === 'intelligence' ? 1024 : null
    })

    /**
     * @descritption Number of max chars for description.
     */
    const minDescriptionLength = computed(() => {
      return form.value.source === 'work' ? 500 : 0
    })

    /**
     * @description Flag to know if show error message about required fields on the botton.
     */
    const isButtonErrorMessageVisible = computed(() => {
      return !hasAllMandatoryField.value && showErrors.value
    })

    /**
     * @description Flag to know if description field is valid.
     */
    const isDescriptionValid = computed(() => {
      return showErrors.value && form.value.submitToGreyCom && form.value.submitToGreyCom.submitForGreyCom &&
             form.value.description && form.value.description.length < 500
    })

    /**
     * @description Returns a flag to know if the user is adding a new item or editting one.
     */
    const isAnEdition = computed(() => {
      return Boolean(props.itemData && Object.keys(props.itemData).length)
    })

    /**
     * @description Returns the label for publish button.
     */
    const publishButtonLabel = computed(() => isAnEdition.value ? 'UPDATE' : 'PUBLISH')

    /**
     * @description Returns a flag to know if the user is adding a new item or editting one.
     */
    const validationErrorMessageLabel = computed(() => {
      return store.state.form.supportingFilesInProcess ? 'There are supporting files uploading!' : thumbnailUploading.value ? 'There is a new thumbnail uploading!' : 'You must fill all required fields!'
    })

    /**
     * @description Returns a flag to know if thumbnail is being uploaded
     */
    const thumbnailUploading = computed(() => {
      return store.state.form.thumbnailInfo && store.state.form.thumbnailInfo.status === 'uploading'
    })

    /**
     * @description Validate if thumbnail selector must be displayed.
     */
    const showThumbnailSelector = computed(() => {
      const fileExt = fileExtension(file.value.name)
      return config.value.videoExtensions.split(',').some((ext) => ext === `${fileExt}`)
    })

    /**
     * @description Set thumbnailUrl variable.
     */
    const setThumbnailUrl = computed(() => {
      return form.value.source === 'work' ? props.itemData.thumbnailUrl : (props.itemData.thumbnailSelectedByUser ? props.itemData.thumbnailUrl : '')
    })

    /**
     * @description Set options list for content dropdown
     */
    const contentTypeOptions = computed(() => {
      return isAnEdition.value && form.value.source === 'work' ? store.state.form.metadata.content.filter(item => item.value !== '5Things') : store.state.form.metadata.content
    })

    /**
     * @description Get file extension
     * @param fileName name of the file
     */
    function fileExtension (fileName) {
      const fileNameSplitted = fileName.split('.')
      return `.${fileNameSplitted[fileNameSplitted.length - 1].toLowerCase()}`
    }

    /**
     * @description Sets a suggested source based on content selected.
     * @param {string} content Content object with suggested app.
     */
    function suggestSource (content) {
      if (!isFormTouched.value && content) {
        isFormTouched.value = true
        emit('touchedForm', true)
        manageBeforeUnloadListener()
      }
      if (isAnEdition.value) {
        return
      }
      setSource(content ? content.suggestedApp : null)
    }

    /**
     * @description Sets the source.
     * @param {string} source Source to set.
     * @param {Boolean} validateChange Flag to know if validation modal should be shown.
     */
    function setSource (source, validateChange = true) {
      if (validateChange && isFileUploadedOnce.value) {
        const sourceChange = form.value.source && form.value.source !== source
        selectedSource.value = source
        if (sourceChange) {
          changeSourceModalRef.value.open()
          return
        }
      }

      if (form.value.source !== source) {
        const promisesToResolveBeforeChange = setPromisesToResolveBefore()
        if (promisesToResolveBeforeChange.length > 0) {
          Promise.all(promisesToResolveBeforeChange).then(() => {
            manageSourceChanges(source)
            form.value.source = source
          })
        } else {
          manageSourceChanges(source)
          form.value.source = source
        }
      }
    }

    /**
     * @description Set delete supporting files promises list
     * @param uploading Boolean flag indicating to consider uploading files
     */
    function setPromisesToResolveBefore (uploading = false) {
      const promisesToResolveBefore = []
      const supportingFiles = store.state.form.uploadList.filter(uploadFile => uploadFile.multiple && (uploading ? uploadFile.status === 'uploading' : true))
      supportingFiles.forEach(supportingFile => {
        promisesToResolveBefore.push(store.dispatch({
          type: 'form/removeWorkFile',
          tempId: supportingFile.tempId,
          workItemId: supportingFile.workItemId,
          isEdition: false,
          isSupportingFile: true,
          multiple: true
        }))
      })
      return promisesToResolveBefore
    }

    /**
     * @description Manages the logic to update the fields when the source changes.
     * @param {string} source Source selected.
     */
    function manageSourceChanges (source) {
      if (file.value && file.value.id) mainFileUploaderRef.value.cancelUpload()
      const newForm = {}
      newForm.contentType = form.value.contentType
      newForm.source = form.value.source
      industryCategoryRef.value.optionsSelected = []

      form.value = JSON.parse(JSON.stringify({ ...newForm, ...store.state.form.defaultValues, ...config.value.formData[source] }))
      form.value.agency = store.state.form.defaultValues.agency.map(agencies => store.state.form.metadata.agencies.find(agenciesObject => agenciesObject.value === agencies)).filter(agency => agency)
      form.value.region = store.state.form.defaultValues.region.map(regions => store.state.form.metadata.regions.find(regionsObject => regionsObject.value === regions))
    }

    /**
     * @description Searches collaborators based on search term.
     * @param {searchTerm} searchTerm Term to search collaborators.
     */
    function searchPeople (searchTerm) {
      return store.dispatch({
        type: 'form/searchUser',
        searchTerm: searchTerm
      })
    }

    /**
     * @description Publishes the form
     */
    function publishForm () {
      showErrors.value = true
      if (isAValidForm()) {
        showSpinner.value = true
        disableFields.value = true
        manageBeforeUnloadListener(false)
        store.dispatch({
          type: form.value.source === 'work' ? 'form/submitWorkItem' : 'form/submitIntelItem',
          body: form.value.source === 'work' ? createWorkForm() : createIntelForm()
        }).then(() => {
          disableFields.value = false
          showSpinner.value = false
          successPopUpRef.value.open()
        })
      } else {
        if (form.value.source === 'work' ? store.state.form.supportingFilesInProcess === 0 : true) focusOnMissinigField(form.value.source)
      }
    }

    /**
     * @description Rises an event to redirect the user to previous page.
     */
    function goToPrevPage () {
      const promisesToResolveBeforeExit = setPromisesToResolveBefore(true)
      if (promisesToResolveBeforeExit.length > 0) {
        Promise.all(promisesToResolveBeforeExit).then(() => {
          emit('goBack', 'uploaded')
        })
      } else {
        emit('goBack', 'uploaded')
      }
    }

    /**
     * @description Creates an object to send the request to publish a intelligence item.
     */
    function createIntelForm () {
      const temporaryForm = {
        id: file.value.id,
        transactionId: store.state.form.transactionId,
        fileId: file.value.fileId,
        title: form.value.title,
        description: form.value.description,
        year: form.value.year,
        client: form.value.client,
        brand: form.value.brand,
        regions: form.value.region ? form.value.region.map(item => typeof item === 'object' ? item.value : item) : [],
        agencies: form.value.agency ? form.value.agency.map(item => typeof item === 'object' ? item.value : item) : [],
        authors: [form.value.author],
        collaborators: form.value.collaborators || [],
        country: form.value.country,
        media: form.value.media ? form.value.media.map(item => typeof item === 'object' ? item.value : item) : [],
        contentType: form.value.contentType,
        fileName: file.value.name,
        industry: form.value.industries,
        tags: form.value.tags ? form.value.tags.map(item => typeof item === 'object' ? item.value : item) : [],
        isPublicFile: form.value.isPublicFile,
        thumbnailOrigin: getThumbnailData('origin', 'intelligence'),
        thumbnailUrl: getThumbnailData('url')
      }
      return getCleanObject(temporaryForm)
    }

    /**
     * @description Creates an object to send the request to publish a work item.
     */
    function createWorkForm () {
      form.value.assets[0] = {
        id: 1,
        ...form.value.assets[0]
      }

      form.value.submitToGreyCom.files[0] = {
        id: 1,
        ...form.value.submitToGreyCom.files[0]
      }

      const temporaryForm = {
        id: file.value.id,
        transactionId: store.state.form.transactionId,
        title: form.value.title,
        subtitle: form.value.subtitle,
        fileName: file.value.name,
        description: form.value.description,
        assets: form.value.assets,
        submitToGreyCom: form.value.submitToGreyCom,
        authors: [form.value.author],
        collaborators: form.value.collaborators || [],
        extraFields: {
          ...form.value.extraFields,
          agencies: form.value.agency.map(agency => agency.value),
          regions: form.value.region.map(region => region.value),
          client: form.value.client,
          brand: form.value.brand,
          year: form.value.year,
          contentType: form.value.contentType,
          country: form.value.country,
          media: form.value.media.map(media => media.value),
          industries: form.value.industries,
          tags: form.value.tags ? form.value.tags.map(item => typeof item === 'object' ? item.value : item) : []
        },
        thumbnailLastState: getThumbnailData('origin', 'work'),
        thumbnailUrl: getThumbnailData('url')
      }
      return getCleanObject(temporaryForm)
    }

    /**
     * @description Gets the thumbnail data to send to service
     * @param dataToGet the thumbnail data to get
     * @param source the form source
     */
    function getThumbnailData (dataToGet, source) {
      let data = ''
      if (dataToGet === 'url') {
        if (showThumbnailSelector.value) {
          const indexThumbnailUrl = store.state.form.thumbnailSelected.selectionType === 'custom'
            ? 0 : (store.state.form.thumbnailSelected.selectionType === 'autoGenerated' ? 1 : 2)
          data = store.state.form.thumbnailSelected.selections[indexThumbnailUrl]
        } else {
          data = store.state.form.thumbnailSelected.selections[2]
        }
        return data
      } else if (dataToGet === 'origin') {
        if (showThumbnailSelector.value) {
          switch (store.state.form.thumbnailSelected.selectionType) {
            case 'custom':
              data = 'Custom'
              break
            case 'autoGenerated':
              data = 'Autogenerated'
              break
            default:
              data = 'Upload'
              break
          }
        } else {
          data = 'Upload'
        }
        if (source === 'work') data = data.toLowerCase()
      }
      return data
    }

    /**
     * @description Removes empty object from an array.
     * @param {array} array of objects to be cleaned.
     */
    function removeEmptyObjects (array) {
      if (array && Array.isArray(array)) {
        return array.filter(object => {
          if (typeof object === 'object') {
            for (const property in object) {
              return object[property] && (typeof object[property] === 'string' ? object[property].trim() : true)
            }
          } else {
            return true
          }
        })
      }
      return []
    }

    /**
     * @description Cleans an object removing data don't assigned by the user and parsing all the selections of
     * dropdown list to string.
     * @param {object} object to clean.
     */
    function getCleanObject (object) {
      if (!object) {
        return null
      }

      return Object.keys(object).reduce((objectAcumulator, property) => {
        if (!Object.prototype.hasOwnProperty.call(object, property)) {
          return objectAcumulator
        }

        if (Array.isArray(object[property])) {
          objectAcumulator[property] = object[property].map((nestedObject) => {
            if (typeof nestedObject === 'object') {
              return getCleanObject(nestedObject)
            } else {
              return nestedObject
            }
          })
          objectAcumulator[property] = removeEmptyObjects(objectAcumulator[property])
        } else {
          if (typeof object[property] === 'object') {
            if (object[property]) {
              const keys = Object.keys(object[property])
              const keysHaveValueData = keys.some(key => key === 'value')
              const isNotANestedObject = keysHaveValueData && typeof object[property].value !== 'object'
              const isAPerson = keys.some(key => key.toLowerCase() === 'eccode')
              if (isNotANestedObject) {
                if (isAPerson) {
                  objectAcumulator[property] = object[property]
                } else {
                  objectAcumulator[property] = object[property].value
                }
              } else {
                objectAcumulator[property] = getCleanObject(object[property])
              }
            }
          } else {
            objectAcumulator[property] = object[property]
          }
        }
        return objectAcumulator
      }, {})
    }

    /**
     * @description Focus the screen on the first missing value.
     * @param {string} source Source of the form, to identify what are its mandatory values.
     */
    function focusOnMissinigField (source) {
      let fieldsIds = ['form_title', 'form_description', 'form_year', 'form_agency', 'form_client', 'form_brand',
        'form_region', 'form_country', 'form_author', 'form_media', 'form_industries']
      let fields = {
        form_title: form.value.title,
        form_description: form.value.description,
        form_year: form.value.year,
        form_agency: form.value.agency,
        form_client: form.value.client,
        form_brand: form.value.brand,
        form_region: form.value.region,
        form_country: form.value.country,
        form_author: form.value.author,
        form_media: form.value.media,
        form_industries: form.value.industries
      }

      if (source === 'work') {
        fieldsIds = [...fieldsIds, 'form_subtitle', 'form_hive', 'form_submitterApprover', 'form_extraImageWork']
        fields = {
          ...fields,
          ...{
            form_subtitle: form.value.subtitle,
            form_submitterApprover: form.value.submitToGreyCom.approver,
            form_extraImageWork: form.value.submitToGreyCom.files[0].file,
            form_hive: form.value.extraFields.coe
          }
        }
      }
      for (let i = 0; i < fieldsIds.length; i++) {
        const element = document.getElementById(fieldsIds[i])
        const isObjectEmtpty = (obj) => !obj || Object.keys(obj).length === 0

        if (fieldsIds[i] === 'form_description' && form.value.submitToGreyCom && form.value.submitToGreyCom.submitForGreyCom && element && !(element.value.length > 500)) {
          element.focus()
          break
        }

        if (fieldsIds[i] === 'form_hive' && form.value.submitToGreyCom && form.value.submitToGreyCom.submitForGreyCom && element && !form.value.extraFields.coe) {
          element.focus()
          break
        }

        if (element && !element.value && isObjectEmtpty(fields[fieldsIds[i]]) && fieldsIds[i] !== 'form_hive') {
          element.focus()
          break
        }
      }
    }

    /**
     * @desrciption Returns a flag to know if all mandatory fields are completed.
     */
    function isAValidForm () {
      if (form.value.source === 'intelligence') {
        hasAllMandatoryField.value = isAValidGenericForm() && isAValidIntelligenceForm()
      } else {
        hasAllMandatoryField.value = isAValidGenericForm() && isAValidWorkForm()
      }
      return hasAllMandatoryField.value
    }

    /**
     * @desrciption Returns a flag to know if all mandatory fields are completed in the generic form.
     */
    function isAValidGenericForm () {
      return form.value.contentType && form.value.title && form.value.description && form.value.year &&
             form.value.agency.length && form.value.client && form.value.brand && form.value.region.length &&
             form.value.country && form.value.author && form.value.media.length && form.value.industries.length &&
             file.value.status && file.value.status === 'completed' && !thumbnailUploading.value
    }

    /**
     * @desrciption Returns a flag to know if all conditions are fulfilled in the intell form.
     */
    function isAValidIntelligenceForm () {
      return form.value.description.length <= 1024
    }

    /**
     * @desrciption Returns a flag to know if all mandatory fields are completed in the work form.
     */
    function isAValidWorkForm () {
      if (form.value.submitToGreyCom.submitForGreyCom) {
        return form.value.submitToGreyCom.approver && form.value.subtitle &&
               form.value.description.length >= 500 && form.value.submitToGreyCom.files[0].file && !store.state.form.supportingFilesInProcess && form.value.extraFields.coe
      }

      return form.value.subtitle && !store.state.form.supportingFilesInProcess
    }

    /**
     * @description Sets the info of the file.
     * @param {uploadedFile} file file information.
     */
    function setFileInfo (uploadedFile) {
      if (isAnEdition.value) {
        return
      }

      file.value = uploadedFile
      isFileUploadedOnce.value = true

      if (file.value && Object.keys(file.value).length !== 0) {
        if (form.value.source === 'work') {
          setWorkFileInfo(uploadedFile)
        }
        if (file.value.status === 'completed') {
          isAValidForm()
        }
      } else {
        showErrors.value = false
      }
    }

    /**
     * @description Adds/Removes listener for beforeunload event.
     * @param {boolean} addEvent Flag to know if listener should be added or removed.
     */
    function manageBeforeUnloadListener (addEvent = true) {
      window[addEvent ? 'addEventListener' : 'removeEventListener']('beforeunload', listenerChangePage)
    }

    /**
     * @description Listens when the users wants to change the page and shows a prevent modal.
     * @param {event} event fired by the user.
     */
    function listenerChangePage (event) {
      event.preventDefault()
      event.returnValue = ''
    }

    /**
     * @description Sets the information of the file, to know what kind of assets will be published.
     * @param {file} file File with the assetType to set it on the form.
     */
    function setWorkFileInfo (file) {
      const typeUploaded = file.status !== 'failed' && file.assetType ? file.assetType : null
      if (typeUploaded && lastTypeUploaded.value && typeUploaded !== lastTypeUploaded.value) {
        form.value.assets[0].extraFields = {}
      }
      if (typeUploaded) {
        lastTypeUploaded.value = typeUploaded
      }

      form.value.assets[0].assetType = typeUploaded
    }

    /**
     * @description Populates the form object with generic data used by intel and work items.
     */
    function populateGenericDataOnEdition () {
      const form = {
        source: props.itemData.source,
        title: props.itemData.title,
        description: props.itemData.description,
        collaborators: props.itemData.collaborators,
        author: {
          ...props.itemData.authors[0],
          value: `${props.itemData.authors[0].name} (${props.itemData.authors[0].email})`
        }
      }
      return form
    }

    /**
     * @description Populates the input tree of industries, using the previously selected values.
     * @param {Array} industriesSelected array with the selection of industries.
     */
    function populatesIndustriesTree (industriesSelected) {
      if (industriesSelected && industriesSelected.length) {
        store.commit({
          type: 'form/SET_INITIAL_INDUSTRIES',
          industries: industriesSelected
        })
      }
    }

    /**
     * @description Populates the form based on the intelligence structure.
     */
    function populateIntelDataOnEdition () {
      populatesIndustriesTree(props.itemData.industry)

      const form = {
        contentType: props.itemData.contentType,
        agency: props.itemData.agencies.map(agencies => store.state.form.metadata.agencies.find(agenciesObject => agenciesObject.value === agencies)),
        year: props.itemData.year,
        country: props.itemData.country,
        industries: props.itemData.industry,
        brand: props.itemData.brand,
        region: props.itemData.regions.map(regions => store.state.form.metadata.regions.find(regionsObject => regionsObject.value === regions)),
        client: store.state.form.metadata.clients.find(client => client.value === props.itemData.client),
        media: props.itemData.media.map(media => store.state.form.metadata.media.find(mediaObject => mediaObject.value === media)),
        tags: props.itemData.tags.map(tags => ({ value: tags, id: tags })),
        isPublicFile: props.itemData.allowView
      }

      file.value = {
        id: props.itemData.id,
        fileId: null,
        name: props.itemData.fileName,
        status: 'completed'
      }

      return form
    }

    /**
     * @description Populates the form based on the work structure.
     */
    function populateWorkDataOnEdition () {
      const itemData = JSON.parse(JSON.stringify(props.itemData))
      populatesIndustriesTree(itemData.extraFields.industries)

      file.value = {
        id: itemData.id,
        assetType: itemData.assets[0].assetType,
        name: itemData.assets[0].fileName,
        status: 'completed'
      }

      const form = {
        year: itemData.extraFields.year,
        agency: itemData.extraFields.agencies.map(agencies => store.state.form.metadata.agencies.find(agenciesObject => agenciesObject.value === agencies)),
        brand: itemData.extraFields.brand,
        region: itemData.extraFields.regions.map(regions => store.state.form.metadata.regions.find(regionsObject => regionsObject.value === regions)),
        country: itemData.extraFields.country,
        subtitle: itemData.subtitle,
        contentType: itemData.extraFields.contentType,
        client: store.state.form.metadata.clients.find(client => client.value === itemData.extraFields.client),
        media: itemData.extraFields.media.map(media => store.state.form.metadata.media.find(mediaObject => mediaObject.value === media)),
        tags: itemData.extraFields.tags.map(tags => ({ value: tags, id: tags })),
        assets: [{ assetType: itemData.assets[0].assetType }]
      }

      form.submitToGreyCom = itemData.submitToGreyCom
      form.extraFields = itemData.extraFields
      form.assets[0].extraFields = itemData.assets[0].extraFields

      if (itemData.submitToGreyCom && itemData.submitToGreyCom.submitForGreyCom) {
        const approver = {
          ...itemData.submitToGreyCom.approver,
          value: `${itemData.submitToGreyCom.approver.name} (${itemData.submitToGreyCom.approver.email})`
        }
        form.submitToGreyCom.approver = approver

        if (itemData.submitToGreyCom.files && itemData.submitToGreyCom.files.length) {
          store.commit({
            type: 'form/SET_SUPPORTING_EXTRA_IMAGE_ID',
            supportingExtraImageId: itemData.submitToGreyCom.files[0].id
          })
          form.submitToGreyCom.files = [{
            id: itemData.submitToGreyCom.files[0].id,
            file: {
              status: 'edition',
              workItemId: props.itemData.id,
              ...itemData.submitToGreyCom.files[0].file
            }
          }]
        } else {
          form.submitToGreyCom.files = [{ file: null, id: 1 }]
        }
      } else {
        form.submitToGreyCom = { submitForGreyCom: false, files: [{ file: null }] }
      }

      return form
    }

    /**
     * @description Manages to set the form as touched.
     */
    function changeHandler () {
      if (isEditionDataLoaded.value && !isFormTouched.value) {
        manageBeforeUnloadListener(false)
        manageBeforeUnloadListener()
        isFormTouched.value = true
        emit('touchedForm', true)
      }
    }

    /**
     * @description Validate if form is valid whenever it changes.
     */
    watch((context) => [form.value.title, form.value.description, form.value.year, form.value.agency, form.value.client, form.value.brand,
      form.value.region, form.value.country, form.value.author, form.value.media, form.value.industries, file.value, store.state.form.supportingFilesInProcess, store.state.form.thumbnailInfo && store.state.form.thumbnailInfo.status], () => {
      if (showErrors.value) {
        isAValidForm()
      }
    })

    /**
     * @description Rises an event to go previous page.
     */
    function cancelEdition () {
      emit('goBack')
    }

    /**
     * @description Open/close award modal
     * @param flag boolean flag to open or close
     */
    function toggleAwardModal (flag = true) {
      awardModalOpen.value = flag
    }

    /**
     * @description set validation active flag
     * @param flag boolean flag
     */
    function setValidationActive (flag) {
      validationActive.value = flag
    }

    /**
     * @description Populates the form when the user wants to update an item.
     */
    watch(() => props.itemData, () => {
      if (props.itemData && Object.keys(props.itemData).length) {
        const formGenericData = populateGenericDataOnEdition()
        if (props.itemData.source === 'intelligence') {
          form.value = { ...formGenericData, ...populateIntelDataOnEdition() }
        } else {
          form.value = { ...formGenericData, ...populateWorkDataOnEdition() }
        }
        nextTick(() => {
          isEditionDataLoaded.value = true
        })
      }
    })

    /**
     * @description Reset thumbnail url prop
     */
    function resetThumbnailUrl () {
      if (isAnEdition.value) emit('resetThumbnailUrl')
    }

    return {
      form,
      showErrors,
      config,
      hasAllMandatoryField,
      isFormTouched,
      file,
      lastTypeUploaded,
      selectedSource,
      isFileUploadedOnce,
      isEditionDataLoaded,
      isContentTypeDisabled,
      isSourceDisabled,
      isFileUploaderDisabled,
      isButtonErrorMessageVisible,
      isDescriptionValid,
      isAnEdition,
      publishButtonLabel,
      maxDescriptionLength,
      minDescriptionLength,
      store,
      changeSourceModalRef,
      successPopUpRef,
      suggestSource,
      setSource,
      manageSourceChanges,
      searchPeople,
      publishForm,
      goToPrevPage,
      createIntelForm,
      createWorkForm,
      removeEmptyObjects,
      getCleanObject,
      focusOnMissinigField,
      isAValidForm,
      isAValidGenericForm,
      isAValidIntelligenceForm,
      isAValidWorkForm,
      setFileInfo,
      manageBeforeUnloadListener,
      listenerChangePage,
      setWorkFileInfo,
      populateGenericDataOnEdition,
      populatesIndustriesTree,
      populateIntelDataOnEdition,
      populateWorkDataOnEdition,
      changeHandler,
      cancelEdition,
      awardModalOpen,
      toggleAwardModal,
      validationActive,
      setValidationActive,
      resetThumbnailUrl,
      validationErrorMessageLabel,
      regionKey,
      agencyOfficeKey,
      disableFields,
      showThumbnailSelector,
      mainFileUploaderRef,
      setThumbnailUrl,
      industryCategoryRef,
      showSpinner,
      contentTypeOptions
    }
  }
}
</script>

<template>
  <section class="multipleToastMessagesContainer" ref="multipleToastsContainerRef">
    <TransitionGroup
      name="multipleToast"
      tag="section"
      class="multipleToastMessagesContainer__animation">
      <toastMessage
        v-for="(file, index) in store.state.currentToasts" :key="file"
        message="invalidFormat"
        :file="file.file"
        :autoClose="true"
        :data-index="index"
        @closeToast="closeToast($event, index)">
      </toastMessage>
    </TransitionGroup>
    <section class="multipleToastMessagesContainer__hidden">
      <toastMessage
        v-for="(file) in invalidFiles" :key="file"
        class="hiddenToast"
        message="invalidFormat"
        :file="file"
        @toastHeight="setToastsToShow($event)"
      ></toastMessage>
    </section>
  </section>
</template>

<script>
import toastMessage from '@/components/toastMessage/toastMessage'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'multipleToastMessages',
  components: {
    toastMessage
  },
  props: {
    invalidFiles: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const toastsTotalHeight = ref(0)
    const toastsToRemove = ref([])
    const multipleToastsContainerRef = ref(null)
    const isToolbarHorizontal = ref(false)

    onMounted(() => {
      handleResize()
      resizeSetUp()
    })

    /**
     * @description Sets the array of toasts to show according the screen size
     * @param height The height of toast
     * @param file The file of toast
     */
    function setToastsToShow ({ height, file }) {
      const availableSpace = window.innerHeight - 109
      const toastHeight = height + 15
      toastsTotalHeight.value += toastHeight
      if (toastsTotalHeight.value <= availableSpace) {
        store.commit({
          type: 'SET_TOASTS_ARRAY',
          current: true,
          file,
          height
        })
        toastsToRemove.value.push(file)
      } else {
        toastsTotalHeight.value -= toastHeight
        const toastIndex = store.state.nextToasts.findIndex(toast => toast.file === file)
        if (toastIndex === -1) {
          store.commit({
            type: 'SET_TOASTS_ARRAY',
            current: false,
            file,
            height
          })
        }
      }
    }

    /**
     * @description Sets new toasts to show from the next toasts array
     */
    function setNextToasts () {
      toastsToRemove.value = []
      store.state.nextToasts.forEach(toast => {
        setToastsToShow(toast)
      })
      store.commit({
        type: 'REMOVE_NEXT_TOASTS',
        number: toastsToRemove.value.length
      })
    }

    /**
     * @description Remove toast from array when it is closed and set new toasts to be shown
     * @param file The file of toast
     * @param height The height of toast
     * @param event DOM event
     */
    function closeToast ({ file, height }) {
      store.commit({
        type: 'REMOVE_TOAST',
        file
      })
      const toastHeight = height + 15
      toastsTotalHeight.value -= toastHeight

      setTimeout(() => {
        setNextToasts()
      }, 500)
      emit('closeToast', file)
    }

    /**
     * @description Sets right position of toast
     */
    function setToastPosition () {
      const scrollbarWidth = window.innerWidth - (document.querySelector('#app #app').clientWidth + (isToolbarHorizontal.value ? 0 : 112))
      const rightPosition = scrollbarWidth + 49
      multipleToastsContainerRef.value.style.setProperty('--rightPosition', rightPosition + 'px')
    }

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      if (store.state.isMobile || window.innerWidth < 1024) {
        isToolbarHorizontal.value = true
      } else {
        isToolbarHorizontal.value = false
      }
      setToastPosition()
    }

    return {
      store,
      closeToast,
      setToastsToShow,
      multipleToastsContainerRef
    }
  }
}
</script>

<template>
  <genericModalLogic class="containerFileWorkUploadedModal"
                     :class="{editionMode: isAnEdition}"
                     :title="title"
                     v-if="isModalVisible"
                     @closeModal="close">
      <p class="containerFileWorkUploadedModal__message">
        <span class="grey-icon-success containerFileWorkUploadedModal__message--image" v-if="!isAnEdition"></span>
        <span class="containerFileWorkUploadedModal__message--message">{{titleMessage}}</span>
        <span class="containerFileWorkUploadedModal__message--additionalInfo">{{message}}</span>
      <button @click.stop="close" class="containerFileWorkUploadedModal__message--button">OK</button>
    </p>
  </genericModalLogic>
</template>
<script>
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import { computed, ref } from 'vue'
export default {
  name: 'fileWorkUploadedModal',
  props: {
    isAnEdition: {
      type: Boolean,
      default: false
    }
  },
  components: {
    genericModalLogic
  },
  setup (props, { emit }) {
    const isModalVisible = ref(false)

    /**
     * @description Returns the title of the modal.
     */
    const title = computed(() => {
      return props.isAnEdition ? 'File Edition' : ''
    })

    /**
     * @description Returns the title of the main message.
     */
    const titleMessage = computed(() => {
      return props.isAnEdition ? '' : 'Successfully Uploaded!'
    })

    /**
     * @description Returns the message to show.
     */
    const message = computed(() => {
      return props.isAnEdition ? 'Edition successfully completed.' : 'The file will be published shortly.'
    })

    /**
     * @description Opens the modal.
     */
    function open () {
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param {event} event fired by the user.
     */
    function close (event) {
      event.preventDefault()
      emit('close')
      isModalVisible.value = false
    }

    return {
      isModalVisible,
      title,
      titleMessage,
      message,
      open,
      close
    }
  }
}

</script>

<template>
  <section class="containerInputTree" v-click-away="showOptionsAsNotVisible" ref="dropdown" :key="keyToRefresh">
    <label class="containerInputTree__label" :class="{'required': required}">{{ label }}</label>
    <section class="containerInputTree__containerInput" :class="{'error': isRequiredErrorVisible }">
      <genericPill v-for="(item, index) in optionsSelected" :key="index"
            :label="item" class="containerInputTree__containerInput--pill"
            @clearPill="removePill(item)"/>
      <input class="containerInputTree__containerInput--input"
            placeholder="Start typing..."
            v-model="searchTerm"
            ref="input"
            :id="id"
            @input="launchThrottledSearch"
            @focus="showOptionsAsVisible"/>
      <span class="material-icons containerInputTree__containerInput--arrow" @mousedown="setFocus">arrow_drop_down</span>
    </section>
    <section class="containerInputTree__containerSelectors" v-show="areOptionsVisible">
      <selectionTree v-for="(branch, index) in tree" :key="index" :field="branch" @changeSelection="updateSelectedOptions(true)"/>
    </section>
    <span class="errorMessage" v-appear="isRequiredErrorVisible">This field cannot be empty</span>
  </section>
</template>

<script>
import { onBeforeUpdate, onMounted, computed, nextTick, ref, watchEffect } from 'vue'
import selectionTree from '../selectionTree/selectionTree'
import genericPill from '@/components/genericPill/genericPill'

export default {
  name: 'inputTree',
  props: {
    separator: {
      type: String,
      default: ' : '
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    initialTree: {
      type: Array,
      required: true
    },
    modelValue: {}
  },
  components: { selectionTree, genericPill },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const areOptionsVisible = ref(false)
    const optionsSelected = ref([])
    const searchTerm = ref(null)
    const updated = ref(false)
    const throttleSearch = ref(null)
    const input = ref(null)
    const dropdown = ref(null)
    const keyToRefresh = ref(1)
    const tree = ref(JSON.parse(JSON.stringify(props.initialTree)))

    /**
     * @description Returns a boolean to indicate that this is a required field and it has not a valid value.
     */
    const isRequiredErrorVisible = computed(() => {
      return props.required && props.showErrors && !optionsSelected.value.length
    })

    onMounted(() => {
      updateInitialPills()
    })

    onBeforeUpdate(() => {
      if (!updated.value) {
        updated.value = true
        updateSelectedOptions()
      }
    })

    /**
     * @description Launches a search of a term but throttled to avoid override the UX.
     */
    function launchThrottledSearch () {
      clearTimeout(throttleSearch.value)
      throttleSearch.value = setTimeout(() => filterTree(tree.value, searchTerm.value), 200)
    }

    /**
     * @description Toggles visibility of options as invisible.
     */
    function showOptionsAsNotVisible () {
      areOptionsVisible.value = false
      searchTerm.value = null
    }

    /**
     * @description Updates the UI to show previous selected options.
     */
    function updateInitialPills () {
      setTimeout(() => {
        keyToRefresh.value = keyToRefresh.value + 1
      }, 0)
    }

    /**
     * @description Toggles visibility of options as visible.
     */
    function showOptionsAsVisible () {
      areOptionsVisible.value = true
      filterTree(tree.value, searchTerm.value)
    }

    /**
     * @description Filters the options of a tree to only show those parents that match with a given term.
     * @param {array} array to iterate an search coincidences.
     * @param {term} string to be used as keyword.
     */
    function filterTree (array, term) {
      const cleanTerm = cleanString(term)
      if (Array.isArray(array)) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index]
          element.isVisible = isASubstring(element.labelSearch, cleanTerm)
        }
        array = Object.assign({}, array)
      }
    }

    /**
     * @description Returns a flag to indicate if a string 'B' is a substring of string 'A'.
     * @param {stringA} string to chek if string 'B' forms part of it.
     * @param {stringB} string to check if is part of string 'A'.
     */
    function isASubstring (stringA, stringB) {
      const index = stringA.indexOf(stringB)
      return index > -1
    }

    /**
     * @description Removes blank spaces and parse the string to lower case to be compared.
     * @param {term} string to be cleaned.
     */
    function cleanString (term) {
      return !term ? '' : term.toLowerCase().trim()
    }

    /**
     * @description Updates the list of pills and the model based on the selections of the user.
     * @param {launchChange} flag to know if change event need to be launched.
     */
    function updateSelectedOptions (launchChange) {
      const selectionLabels = []
      const pills = []

      tree.value.forEach(parent => {
        if (parent.isSelected) {
          if (!parent.isIndeterminate) {
            pills.push(parent.label)
          }

          parent.children.forEach(child => {
            if (child.isSelected) {
              const childLabel = `${parent.label}${props.separator}${child.label}`
              selectionLabels.push(childLabel)

              if (parent.isIndeterminate) {
                pills.push(childLabel)
              }
            }
          })
        }
      })

      optionsSelected.value = pills
      emit('update:modelValue', selectionLabels)
      if (launchChange) {
        dropdown.value.dispatchEvent(new Event('change', { bubbles: true }))
      }
    }

    /**
     * @description Removes a pill from the list removing the selection of its linked checkbox.
     * @param {label} string label to be searched and deselected from the tree.
     */
    function removePill (label) {
      const labelSplitted = label.split(`${props.separator}`)
      const industrySelected = labelSplitted[0]
      const categorySelected = labelSplitted[1]

      if (!categorySelected) {
        tree.value.forEach(branch => {
          if (branch.label === industrySelected) {
            branch.isSelected = false
            branch.children.forEach((category, index) => {
              branch.children[index] = Object.assign({}, category, { isSelected: false })
            })
          }
        })
      } else {
        tree.value.forEach(branch => {
          branch.children.forEach((category, index) => {
            if (branch.label === industrySelected && category.label === categorySelected) {
              branch.children[index] = Object.assign({}, category, { isSelected: false })
            }
          })
        })
      }
      dropdown.value.dispatchEvent(new Event('change', { bubbles: true }))
    }

    /**
     * @description Sets the focus on the input to show the tree.
     */
    function setFocus () {
      nextTick(() => {
        input.value.focus()
      })
    }

    /**
     * @description Sets the initial state of the tree.
     */
    watchEffect(() => props.initialTree, () => {
      tree.value = JSON.parse(JSON.stringify(props.initialTree))
    })

    return {
      areOptionsVisible,
      optionsSelected,
      searchTerm,
      updated,
      throttleSearch,
      input,
      dropdown,
      keyToRefresh,
      isRequiredErrorVisible,
      launchThrottledSearch,
      showOptionsAsNotVisible,
      showOptionsAsVisible,
      updateSelectedOptions,
      removePill,
      setFocus,
      tree
    }
  }
}
</script>

<template>
  <section class="containerSelectionTree" v-show="field.isVisible">
    <span class="containerSelectionTree__item">
      <span
        class="material-icons containerSelectionTree__item--arrow"
        v-if="field.children"
        @click="toggleChildrenVisibility()"
        :class="{'open': areChildrenVisible}">arrow_right
      </span>
      <genericCheckBox v-model="isSelected"
                       :indeterminate="isIndeterminate"
                       class="containerSelectionTree__check"
                       :label="field.label"
                       @change="changeSelectionHandler($event)">
      </genericCheckBox>
    </span>
    <section v-show="field.children && areChildrenVisible" class="containerSelectionTree__children">
      <selectionTree
        v-for="(child, index) in field.children"
        :field="child"
        :key="index"
        @changeSelection="changeSelection($event)"
      />
    </section>
  </section>
</template>

<script>
import { ref, watch } from 'vue'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'

export default {
  name: 'selectionTree',
  props: {
    field: {
      type: Object
    }
  },
  components: {
    genericCheckBox
  },
  emits: ['changeSelection'],
  setup (props, { emit }) {
    const isIndeterminate = ref(props.field.isIndeterminate)
    const isSelected = ref(props.field.isSelected)
    const areChildrenVisible = ref(false)

    /**
      * @description Watch for changes on field property
      */
    watch(() => props.field, (newValue) => {
      updatesHandler(newValue.isSelected)
    })

    /**
     * @description Sets the visibility as a given param, if no param is sent toggless visibility.
     * @param {areVisible} boolean to indicate if children have to be visible or not.
     */
    function toggleChildrenVisibility (areVisible) {
      areChildrenVisible.value = areVisible || !areChildrenVisible.value
    }

    /**
     * @description Handles any change on the selection, updating children and rising events to notify the change.
     * @param {isSelected} boolan flag to indicate if now the field is selected or not.
     */
    function changeSelectionHandler (isSelected) {
      if (isSelected) {
        toggleChildrenVisibility(true)
      }
      setSelectedValue(isSelected)
      updateChildren(isSelected)
      emit('changeSelection', isSelected)
    }

    /**
     * @description Sets the value of the model as selected or deselected.
     * @param {isValueSelected} boolan flag to indicate if now the field is selected or not.
     */
    function setSelectedValue (isValueSelected) {
      isSelected.value = isValueSelected
      /* eslint-disable */
      props.field.isSelected = isValueSelected
      /* eslint-enable */
      setIndeterminateValue(false)
    }

    /**
     * @description Bubbles up the event of change to notify to parent component about any change.
     * @param {isSelected} boolan flag to indicate if now the field is selected or not.
     */
    function changeSelection (isSelected) {
      validateSelectStatus()
      emit('changeSelection', isSelected)
    }

    /**
     * @description Sets the flag to know if the value of the checkbox is indeterminated.
     * @param {isValueIndeterminate} boolan flag to indicate if now the field is indeterminate or not.
     */
    function setIndeterminateValue (isValueIndeterminate) {
      isIndeterminate.value = isValueIndeterminate
      /* eslint-disable */
      props.field.isIndeterminate = isValueIndeterminate
      /* eslint-enable */
    }

    /**
     * @description Updates status of the children based on the selection of the parent.
     * @param {isSelected} boolan flag to indicate if now the field is selected or not.
     */
    function updateChildren (isSelected) {
      const children = props.field.children
      if (children) {
        children.forEach((child, index) => {
          children[index] = Object.assign({}, child, { isSelected: isSelected })
        })
      }
    }

    /**
     * @description Validates status of the current field based on the status of its children.
     */
    function validateSelectStatus () {
      const children = props.field.children
      if (children) {
        const numberChildrenSelected = children.filter(child => child.isSelected).length
        setSelectedValue(numberChildrenSelected !== 0)
        const isIndeterminate = children.length && numberChildrenSelected !== 0 ? children.length !== numberChildrenSelected : false
        setIndeterminateValue(isIndeterminate)
      }
    }

    /**
     * @description Manages the changes to refresh the component and its children when a change from
     * parent components are made.
     * @param {isValueSelected} boolan flag to indicate if now the field is selected or not.
     */
    function updatesHandler (isValueSelected) {
      if (isSelected.value !== isValueSelected) {
        emit('changeSelection', props.field.isSelected)
      }

      isSelected.value = props.field.isSelected
      isIndeterminate.value = props.field.isIndeterminate

      if (!isIndeterminate.value) {
        updateChildren(isSelected.value)
      }
    }

    return {
      isIndeterminate,
      isSelected,
      areChildrenVisible,
      changeSelectionHandler,
      changeSelection,
      toggleChildrenVisibility
    }
  }
}
</script>

<template>
  <section class="containerFileUpload" :class="{'fileFromEdition': fileInfo.fileFromEdition}">
    <section class="containerFileUpload__info" :class="fileInfo.status">
      <span class="grey-icon-success"></span>
      <spinner class="containerFileUpload__info--spinner" />
      <span class="containerFileUpload__info--fileName" :title="fileInfo.name">{{ fileInfo.name }}</span>
      <span class="containerFileUpload__info--percentageLabel">{{Math.min(fileInfo.progress, 100)}}% of {{fileInfo.size}}</span>
    </section>
    <section class="containerFileUpload__actions">
      <progressBar class="containerFileUpload__actions--progressBar" ref="progressBar" :progressDynamic="fileInfo.progress" :indeterminated="false"/>
      <span class="grey-icon-clear" :title="clearTitle" @click="clearFileHandler"/>
    </section>
    <transition name="fade">
      <section class="containerFileUpload__deleteModal" v-if="showDeleteModal" v-click-away="hideDeleteModal">
        <section class="containerFileUpload__deleteModal--modal">
          <span class="label">Are you sure you want to delete this?</span>
          <button class="confirmButton" @click.stop.prevent="deleteFile">Delete</button>
          <button class="cancelButton" @click.stop.prevent="hideDeleteModal">Cancel</button>
        </section>
      </section>
    </transition>
  </section>
</template>

<script>
import progressBar from '@/components/progressBar/progressBar'
import spinner from '@/components/spinner/spinner'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'fileUpload',
  props: {
    itemId: {
      type: String,
      default: null
    },
    fileInfo: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    }
  },
  components: {
    progressBar,
    spinner
  },
  emits: ['fileDeleted'],
  setup (props, { emit }) {
    const store = useStore()
    const showDeleteModal = ref(false)

    /**
     * @description Get Clear button tooltip label
     */
    const clearTitle = computed(() => {
      return props.fileInfo.status === 'uploading' ? 'Cancel' : 'Delete'
    })

    /**
     * @description Executes clear action
     */
    function clearFileHandler () {
      switch (props.fileInfo.status) {
        case 'uploading':
          store.dispatch({
            type: 'form/removeWorkFile',
            tempId: props.fileInfo.tempId,
            workItemId: props.itemId,
            isEdition: false,
            isSupportingFile: true,
            multiple: true
          }).then(() => {
            store.commit({
              type: 'form/SET_SUPPORTING_FILES_IN_PROCESS',
              add: false
            })
          })
          break
        case 'completed':
          showDeleteModal.value = true
          break
        default:
          store.commit({
            type: 'form/DELETE_FILE',
            id: props.fileInfo.tempId
          })
          break
      }
    }

    /**
     * @description Hide delete confirmation modal
     */
    function hideDeleteModal () {
      showDeleteModal.value = false
    }

    /**
     * @description Delete file
     */
    function deleteFile () {
      store.dispatch({
        type: 'form/removeWorkFile',
        tempId: props.fileInfo.tempId,
        workItemId: props.itemId,
        isEdition: false,
        isSupportingFile: true,
        multiple: true
      }).then(() => {
        emit('fileDeleted')
        showDeleteModal.value = false
      })
    }

    return {
      store,
      showDeleteModal,
      clearTitle,
      clearFileHandler,
      hideDeleteModal,
      deleteFile
    }
  }
}
</script>

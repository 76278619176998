<template>
  <section class="containerPill">
    <span class="grey-icon-close containerPill__closeButton" @click="clearPill()"></span>
    <span class="containerPill__label">{{label}}</span>
  </section>
</template>

<script>
export default {
  name: 'genericPill',
  props: ['label'],
  emits: ['clearPill'],
  setup (props, { emit }) {
    /**
     * @description Propagates event to notify the pill has been deleted.
     */
    function clearPill () {
      emit('clearPill')
    }

    return {
      clearPill
    }
  }
}
</script>

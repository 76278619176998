<template>
  <section class="containerGenericAutocomplete">
    <input class="containerGenericAutocomplete__input"
           ref="input"
           type="text"
           :id="id"
           :name="randomName"
           @blur="closeOptions"
           @focus="focusHandler"
           @click="focusHandler"
           @input="updateInputByUser()"
           @keydown="specialKeysToDetect && handlerKeydownEvent($event)"
           @keydown.up="moveSelection(-1)"
           @keydown.down="moveSelection(1)"
           @keydown.backspace="launchDeleteOption"
           @keydown.enter="selectItem($event, optionList[selectedIndex])"
           :placeholder="placeholder"
           :value="optionSelected.value.value"/>
           <slot></slot>
    <section class="containerGenericAutocomplete__list"
             v-if="optionList && optionList.length && showOptions"
             ref="listContainer">
      <ul ref="list">
        <li v-for="(item, index) in optionList" :key="index">
          <span class="containerGenericAutocomplete__list--option"
                :class="{'selected': selectedIndex === index}"
                @click.stop="cancelEvent($event)"
                @mouseover.stop.prevent="setSelectedIndex(index)"
                @mousedown.stop ="selectItem($event, item)">
            {{item.value}}
          </span>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { nextTick, watch, ref, reactive } from 'vue'
export default {
  props: ['placeholder', 'modelValue', 'optionList', 'lenghtToLaunchSearch', 'clearOnSelection', 'specialCharListToDetect',
    'allowUserValues', 'id', 'specialKeysToDetect'],
  emits: ['optionSelected', 'update:modelValue', 'launchSearch', 'deleteOption', 'selectFirstCoincidence'],
  setup (props, { emit }) {
    const optionSelected = reactive({})
    const selectedIndex = ref(0)
    const showOptions = ref(false)
    const throttleTimer = ref(0)
    const validOptionSelected = ref(false)
    const input = ref(null)
    const listContainer = ref(null)
    const list = ref(null)
    const randomName = Math.random()

    initializeOptionSelected()

    /**
     * @description Updates the value given by the parent component.
     * @param {value} value passed by the parent component.
     */
    watch(() => props.modelValue, (newValue, oldValue) => {
      initializeOptionSelected(newValue)
      validOptionSelected.value = true
    })

    /**
     * @description Initialize the option selected according the default value.
     * @param {Object} valueToSet default value to take if option selectes has not a primitive type.
     */
    function initializeOptionSelected (valueToSet = null) {
      if (typeof props.modelValue === 'string' || typeof props.modelValue === 'number') {
        optionSelected.value = { value: props.modelValue }
      } else if (props.modelValue && typeof props.modelValue === 'object' && Object.keys(props.modelValue).length) {
        optionSelected.value = Object.assign({}, props.modelValue)
      } else {
        optionSelected.value = valueToSet || { value: '' }
      }
    }

    /**
     * @description Update input with text typed by user and launches a request once the text is long enough.
     */
    function updateInputByUser () {
      validOptionSelected.value = false
      optionSelected.value.value = input.value.value
      const haveItemsBeenAdded = manageSpecialCharsToAddOptions()
      if (haveItemsBeenAdded) return
      if (props.allowUserValues && !props.specialCharListToDetect) {
        optionSelected.value.value.trim() ? emit('update:modelValue', optionSelected.value) : emit('update:modelValue', null)
      }
      if (optionSelected.value.value.trim().length >= props.lenghtToLaunchSearch) {
        clearTimeout(throttleTimer.value)
        throttleTimer.value = setTimeout(() => {
          const searchTerm = optionSelected.value.value ? optionSelected.value.value.trim() : null
          emit('launchSearch', searchTerm)
          showOptions.value = true
        }, 500)
      } else {
        emit('launchSearch', null)
      }
    }

    /**
     * @description Manages the logic to add an option when an special char is pressed or is present in the input.
     */
    function manageSpecialCharsToAddOptions () {
      if (Array.isArray(props.specialCharListToDetect) && input.value.value) {
        const options = props.specialCharListToDetect.reduce((options, specialChar) => {
          if (Array.isArray(options)) {
            return options.reduce((optionSplitted, option) => [...optionSplitted, ...option.split(specialChar)], [])
          } else {
            return options.split(specialChar)
          }
        }, input.value.value)

        const areThereSpecialChars = options.length > 1

        if (areThereSpecialChars) {
          for (const option of new Set(options)) {
            const optionCleaned = option.trim()
            if (optionCleaned) {
              selectItem(null, { value: optionCleaned, id: optionCleaned })
            }
          }
        }
        return areThereSpecialChars
      }

      return false
    }

    /**
     * @description Emits an event once a user has selected an option with click or enter.
     * @param {event} event of web browser.
     * @param {optionSelectedParam} option selected by the user.
     */
    function selectItem (event, optionSelectedParam) {
      if (!optionSelectedParam) {
        return
      }
      cancelEvent(event)
      emit('optionSelected', Object.assign({ value: optionSelectedParam.value, id: optionSelectedParam.id }, optionSelectedParam))
      emit('update:modelValue', Object.assign({ value: optionSelectedParam.value, id: optionSelectedParam.id }, optionSelectedParam))
      validOptionSelected.value = true
      if (!props.clearOnSelection) {
        optionSelected.value = Object.assign({ value: optionSelectedParam.value, id: optionSelectedParam.id }, optionSelectedParam)
        emit('launchSearch', optionSelected.value.value.trim())
      } else {
        clearInput()
      }
      selectedIndex.value = 0
      showOptions.value = false
      input.value.dispatchEvent(new Event('change', { bubbles: true }))
    }

    /**
     * @description Navitage through the list of options.
     * @param {offset} offset to apply to selected index.
     */
    function moveSelection (offset) {
      selectedIndex.value = selectedIndex.value + offset

      if (selectedIndex.value < 0) {
        selectedIndex.value = props.optionList.length - 1
      }

      if (selectedIndex.value >= props.optionList.length) {
        selectedIndex.value = 0
      }

      nextTick(() => {
        updateScroll()
      })
    }

    /**
     * @description Sets the selected index equal to a given index.
     * @param {Number} index index to be set as selected.
     */
    function setSelectedIndex (index) {
      selectedIndex.value = index
    }

    /**
     * @description Updates scroll of the container to see the current selected option
     */
    function updateScroll () {
      if (!listContainer.value) {
        return
      }
      const topOfListContainer = listContainer.value.getBoundingClientRect().top
      const topOfSelectedOption = listContainer.value.getElementsByClassName('selected')[0].getBoundingClientRect().top
      const bottomOfSelectedOption = listContainer.value.getElementsByClassName('selected')[0].getBoundingClientRect().bottom
      const topOfList = list.value.getBoundingClientRect().top

      if (bottomOfSelectedOption - topOfListContainer > listContainer.value.getBoundingClientRect().height) {
        listContainer.value.scrollTo(0, topOfSelectedOption - topOfList)
      }

      if (topOfListContainer - topOfSelectedOption > 0) {
        listContainer.value.scrollTo(0, listContainer.value.scrollTop - (topOfListContainer - topOfSelectedOption))
      }
    }

    /**
     * @description Closes the list of options.
     */
    function closeOptions (event) {
      showOptions.value = false
      const inputValue = input.value.value ? input.value.value.trim() : input.value.value
      if (inputValue && props.allowUserValues && props.specialCharListToDetect) {
        selectItem(null, { value: inputValue, id: inputValue })
      } else if (!validOptionSelected.value && !props.allowUserValues) {
        optionSelected.value = { label: '' }
        emit('update:modelValue', null)
        emit('optionSelected', null)
      }
    }

    /**
     * @description Launches a search to show the list of options when user focuses on the input.
     * @param {event} event focus event dispatched by the user.
     */
    function focusHandler (event) {
      if (props.disabled) {
        event.stopPropagation()
        event.preventDefault()
        event.target.blur()
        return
      }
      if (!showOptions.value) {
        emit('launchSearch', null)
      }
      showOptions.value = true
      scrollToSelectedOption()
    }

    /**
     * @description  Blocks the normal behaviour of a given event.
     * @param {event} event to be blocked.
     */
    function cancelEvent (event) {
      if (event) {
        event.preventDefault()
      }
    }

    /**
     * @description Sets the focus on the input.
     */
    function setFocusOnInput () {
      input.value.focus()
      focusHandler()
    }

    /**
     * @description Clears the input.
     */
    function clearInput () {
      optionSelected.value = { value: '' }
    }

    /**
     * @description Launches an event to notifyt the user tries to delete a selected option.
     */
    function launchDeleteOption () {
      if (!input.value) {
        emit('deleteOption')
      }
    }

    /**
     * @description Scrolls to previous selected option.
     */
    function scrollToSelectedOption () {
      if (!optionSelected.value.value) {
        return
      }
      nextTick(() => {
        if (!list.value) {
          return
        }
        const options = list.value.querySelectorAll('.containerGenericAutocomplete__list--option')
        let selectedItem = null
        const valueToCompare = optionSelected.value.value.toString()

        for (let index = 0; index < options.length; index++) {
          if (options[index].textContent.trim() === valueToCompare) {
            selectedIndex.value = index
            selectedItem = options[index]
            break
          }
        }
        if (selectedItem) {
          const offset = selectedItem.getBoundingClientRect().top - listContainer.value.getBoundingClientRect().top
          if (offset > 191) {
            listContainer.value.scrollTo(0, offset)
          }
        }
      })
    }

    /**
     * @description Manages the logic to add options when user presses some keys.
     * @event {event} event launched by the user.
     */
    function handlerKeydownEvent (event) {
      const valueToAdd = input.value.value
      if (props.specialKeysToDetect.some(key => key === event.key) && valueToAdd.trim()) {
        selectItem(event, { value: valueToAdd, id: valueToAdd })
      }
    }

    return {
      optionSelected,
      selectedIndex,
      showOptions,
      throttleTimer,
      validOptionSelected,
      input,
      listContainer,
      list,
      updateInputByUser,
      selectItem,
      moveSelection,
      closeOptions,
      focusHandler,
      cancelEvent,
      setFocusOnInput,
      launchDeleteOption,
      manageSpecialCharsToAddOptions,
      handlerKeydownEvent,
      setSelectedIndex,
      randomName
    }
  }
}
</script>

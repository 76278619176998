<template>
  <section class="containerSkeletonUploadFile">
    <div v-for="index in items" :key="index"></div>
    <section class="twoColumns">
      <div></div>
      <div></div>
    </section>
    <div v-for="index in items" :key="index"></div>
  </section>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'skeletonUploadFile',

  setup (props) {
    const items = ref(3)

    return {
      items
    }
  }
}
</script>

<template>
  <section class="containerWorkForm" ref="workFormRef">
    <inputGeneric label="SUBTITLE"
                  v-model:modelValue="form.subtitle"
                  required
                  :showErrors="showErrors"
                  :maxLength=140
                  id="form_subtitle"/>
    <inputListMultipleValues label="TAGS" v-model:modelValue="form.tags" id="form_tags" class="containerWorkForm__tags" :getAsyncOptions="searchTags" idProperty="id" :showLabelFunc="(option) =>  ({ value: `${option.value}`, id: option.id})" allowUserValues :showArrowDropDown="false"/>
    <inputList label="PRODUCER NAME"
                v-model:modelValue="form.extraFields.producerName"
                :showErrors="showErrors"
                :getAsyncOptions="searchExternalPeople"
                :allowUserValues="true"
                :showArrowDropDown="false"
                id="form_producerName"/>
    <inputList label="CENTERS OF EXCELLENCE"
                v-model:modelValue="form.extraFields.coe"
                :showErrors="showErrors"
                :options="store.state.form.workMetadata.coe"
                :required="form.submitToGreyCom.submitForGreyCom"
                id="form_hive">
      <template v-slot:nextToLabel>
        <hiveTooltip/>
      </template>
    </inputList>
    <genericCheckBox v-model="form.submitToGreyCom.submitForGreyCom"
                     class="containerWorkForm__checkBox"
                     @change="changeHandler">
      <span class="containerWorkForm__checkBox--label">Submit for Grey.com</span>
    </genericCheckBox>
    <section class="containerWorkForm__containerSubmitForGrey">
      <label class="containerWorkForm__containerSubmitForGrey--label">
        You agree that this work was reviewed and approved by a Creative Director for use on Grey.com
      </label>
      <template v-if="form.submitToGreyCom.submitForGreyCom">
        <inputList label="SELECT CREATIVE DIRECTOR"
                   class="containerWorkForm__containerSubmitForGrey--creativeDir"
                   v-model:modelValue="form.submitToGreyCom.approver"
                   required
                   :showErrors="showErrors"
                   :getAsyncOptions="searchPeople"
                   :showArrowDropDown="false"
                   placeholder="Start typing..."
                   idProperty="email"
                   id="form_submitterApprover"/>
        <workExtraImageUploader :itemId="fileId" v-model:modelValue="form.submitToGreyCom.files[0].file" :showErrors="showErrors"/>
        <inputGeneric label="ADDITIONAL COMMENTS"
                      v-model:modelValue="form.submitToGreyCom.additionalComments"
                      multilineal
                      class="containerWorkForm__containerSubmitForGrey--comments"
                      id="form_submitterComments"/>
      </template>
    </section>
    <template v-if="form.assets && form.assets[0]">
      <collapsibleSection title="ADVANCED METADATA" class="containerWorkForm__containerAdvancedMetadata" :key="form.assets[0].assetType">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputGeneric label="NOTES" v-model:modelValue="form.extraFields.notes" id="workForm_notes"/>
          <inputListMultipleValues label="COLLABORATORS" v-model:modelValue="form.collaborators" :getAsyncOptions="searchPeople" :showLabelFunc="(option) =>  ({ value: `${option.name} (${option.email})`, id: option.email })" idProperty="email" id="workForm_collaborators" :showArrowDropDown="false"/>
          <inputList label="DISCIPLINE" v-model:modelValue="form.extraFields.discipline" :options="store.state.form.workMetadata.disciplines" id="workForm_discipline"/>
          <section class="twoColumnsContainer">
            <inputGeneric label="PRODUCT" v-model:modelValue="form.extraFields.product" id="workForm_product"/>
            <inputGeneric label="CAMPAIGN NAME" v-model:modelValue="form.extraFields.campaignName" id="workForm_campaignName"/>
            <inputGeneric label="JOB NUMBER" v-model:modelValue="form.extraFields.jobNumber" id="workForm_jobNumber"/>
            <inputList label="LANGUAGE" v-model:modelValue="form.extraFields.language" :options="store.state.form.workMetadata.languages" id="workForm_language"/>
            <inputList label="PRODUCER / CREATIVE PROJECT MANAGER" v-model:modelValue="form.extraFields.producerCreativeProjectManager" :options="store.state.form.workMetadata.producerCreativeProjectManager" id="workForm_producerCreativeProjectManager"/>
            <inputGeneric label="BUSINESS LEAD" v-model:modelValue="form.extraFields.businessLead" id="workForm_businessLead"/>
            <inputList label="ASSET TYPE" v-model:modelValue="form.extraFields.assetType" :options="store.state.form.workMetadata.assetTypes" id="workForm_assetTypes"/>
            <inputList label="ASSET STATUS" v-model:modelValue="form.extraFields.assetStatus" :options="store.state.form.workMetadata.assetStatus" id="workForm_assetStatus"/>
            <datePicker label="ASSET EXPIRY DATE" v-model:modelValue="form.extraFields.assetExpiryDate" id="workForm_assetExpiryDate" @update:modelValue="changeHandler"/>
            <datePicker label="USAGE RIGHTS TERM" v-model:modelValue="form.extraFields.usageRightsTerm" id="workForm_usageRightsTerm" @update:modelValue="changeHandler"/>
            <inputGeneric label="USAGE RIGHTS CONTACT" v-model:modelValue="form.extraFields.usageRightsContact" id="workForm_usageRightsContact"/>
            <inputGeneric label="USAGE RIGHTS COUNTRIES / REGION" v-model:modelValue="form.extraFields.usageRightsCountriesRegion" id="workForm_usageRightsCountriesRegion"/>
          </section>
          <workAwardInput class="containerWorkFormStep__awards" @changeHandler="changeHandler" @openAwardModal="openAwardModal"/>
        </section>
      </collapsibleSection>
      <collapsibleSection title="IMAGE OPTIONAL METADATA" class="containerWorkForm__containerAdvancedMetadata" v-if="form.assets[0].assetType==='Image'">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputList label="PRINT TYPE" v-model:modelValue="form.assets[0].extraFields.printType" :options="store.state.form.workMetadata.printType" id="workForm_printType"/>
          <inputGeneric label="SHOOT TITLE" v-model:modelValue="form.assets[0].extraFields.shootTitle" id="workForm_shootTitle"/>
          <inputGeneric label="PHOTOGRAPHER" v-model:modelValue="form.assets[0].extraFields.photographer" id="workForm_photographer"/>
          <section class="twoColumnsContainer">
            <inputGeneric label="PHOTO SHOOT JOB NUMBER" v-model:modelValue="form.assets[0].extraFields.photoShootJobNumber" id="workForm_photoShootJobNumber"/>
            <datePicker label="SHOOT DATE" v-model:modelValue="form.assets[0].extraFields.shootDate" id="workForm_shootDate" @update:modelValue="changeHandler"/>
            <inputGeneric label="FORMAT SIZE" v-model:modelValue="form.assets[0].extraFields.formatSize" id="workForm_formatSize"/>
            <inputGeneric label="ILLUSTRATOR" v-model:modelValue="form.assets[0].extraFields.illustrator" id="workForm_illustrator"/>
            <inputGeneric label="STOCK PHOTO SUPPLIER" v-model:modelValue="form.assets[0].extraFields.stockPhotoSupplier" id="workForm_stockPhotoSupplier"/>
          </section>
        </section>
      </collapsibleSection>
      <collapsibleSection title="AUDIO OPTIONAL METADATA" class="containerWorkForm__containerAdvancedMetadata" v-if="form.assets[0].assetType==='Audio'">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputGeneric label="MUSIC TITLE" v-model:modelValue="form.assets[0].extraFields.musicTitle" id="workForm_musicTitle"/>
          <inputGeneric label="MUSIC COMPOSER" v-model:modelValue="form.assets[0].extraFields.musicComposer" id="workForm_musicComposer"/>
          <section class="twoColumnsContainer">
            <inputGeneric label="SAMPLE RATE" v-model:modelValue="form.assets[0].extraFields.sampleRate" id="workForm_sampleRate"/>
            <inputGeneric label="SAMPLE SIZE" v-model:modelValue="form.assets[0].extraFields.sampleSize" id="workForm_sampleSize"/>
            <inputGeneric label="BIT RATE" v-model:modelValue="form.assets[0].extraFields.bitRate" id="workForm_bitRate"/>
            <inputList label="CHANNELS" :options="store.state.form.workMetadata.channels" v-model:modelValue="form.assets[0].extraFields.channels" id="workForm_channels"/>
            <inputGeneric label="BIT DEPTH" v-model:modelValue="form.assets[0].extraFields.bitDepth" id="workForm_bitDepth"/>
            <inputList label="BROADCAST VERIFICATION" :options="[{id: 1, value: 'Yes'}, {id: 2, value: 'No'}]" v-model:modelValue="form.assets[0].extraFields.broadcastVerification" id="workForm_broadcastVerification"/>
            <inputList label="LKFS COMPLAINT" :options="[{id: 1, value: 'Yes'}, {id: 2, value: 'No'}]" v-model:modelValue="form.assets[0].extraFields.lkfsComplaint" id="workForm_lkfsComplaint"/>
            <datePicker label="MUSIC USAGE RIGHT EXPIRY DATE" v-model:modelValue="form.assets[0].extraFields.musicUsageRightExpiryDate" id="workForm_musicUsageRightExpiryDate" @update:modelValue="changeHandler"/>
            <inputGeneric label="SECURITY TITLE" v-model:modelValue="form.assets[0].extraFields.securityTitle"  id="workForm_securityTitle"/>
            <datePicker label="LOCAL VO USAGE RIGHT EXPIRY DATE" v-model:modelValue="form.assets[0].extraFields.localVOUsageRightExpiryDate" id="workForm_localVOUsageRightExpiryDate" @update:modelValue="changeHandler"/>
            <inputGeneric label="LOCAL VO STUDIO" v-model:modelValue="form.assets[0].extraFields.localVOStudio" id="workForm_localVOStudio"/>
            <inputGeneric label="EDITORIAL HOUSE" v-model:modelValue="form.assets[0].extraFields.editorialHouse" id="workForm_editorialHouse"/>
            <inputGeneric label="AUDIO POST HOUSE" v-model:modelValue="form.assets[0].extraFields.audioPostHouse" id="workForm_audioPostHouse"/>
            <inputGeneric label="AGENCY PRODUCER" v-model:modelValue="form.assets[0].extraFields.agencyProducer" id="workForm_agencyProducer"/>
            <inputGeneric label="MANAGE AGENCIES" v-model:modelValue="form.assets[0].extraFields.manageAgencies" id="workForm_manageAgencies"/>
            <inputGeneric label="ADVERTISERS" v-model:modelValue="form.assets[0].extraFields.advertisers" id="workForm_advertisers"/>
            <inputGeneric label="MANAGE ADVERTISERS" v-model:modelValue="form.assets[0].extraFields.manageAdvertisers" id="workForm_manageAdvertisers"/>
            <inputList label="TRAFFIC DESTINATION" v-model:modelValue="form.assets[0].extraFields.trafficDestination" :options="store.state.form.workMetadata.trafficDestination" id="workForm_trafficDestination"/>
            <inputGeneric label="MANAGE BRANDS" v-model:modelValue="form.assets[0].extraFields.manageBrands" id="workForm_manageBrands"/>
          </section>
        </section>
      </collapsibleSection>
      <collapsibleSection title="VIDEO OPTIONAL METADATA" class="containerWorkForm__containerAdvancedMetadata" v-if="form.assets[0].assetType==='Video'">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputGeneric label="ISCI" v-model:modelValue="form.assets[0].extraFields.isci" id="workForm_isci"/>
          <inputGeneric label="ALTERNATE TITLE" v-model:modelValue="form.assets[0].extraFields.alternateTitle" id="workForm_alternateTitle"/>
          <section class="twoColumnsContainer">
            <inputList label="ASPECT RATIO" v-model:modelValue="form.assets[0].extraFields.aspectRatio" :options="store.state.form.workMetadata.aspectRatio" id="workForm_aspectRatio"/>
            <inputList label="FRAME RATE" v-model:modelValue="form.assets[0].extraFields.frameRate" :options="store.state.form.workMetadata.frameRate" id="workForm_frameRate"/>
            <inputList label="RESOLUTION" v-model:modelValue="form.assets[0].extraFields.resolution" :options="store.state.form.workMetadata.resolution" id="workForm_resolution"/>
            <inputGeneric label="AUDIO MIX" v-model:modelValue="form.assets[0].extraFields.audioMix" id="workForm_audioMix"/>
            <inputGeneric label="SPECIAL EFFECTS" v-model:modelValue="form.assets[0].extraFields.specialEffects" id="workForm_specialEffects"/>
            <inputGeneric label="CELEBRITY TALENT" v-model:modelValue="form.assets[0].extraFields.celebrityTalent" id="workForm_celebrityTalent"/>
            <inputList label="SUBTITLED" v-model:modelValue="form.assets[0].extraFields.subtitled" :options="[{id: 1, value: 'Yes'}, {id: 2, value: 'No'}]" id="workForm_subtitled"/>
            <datePicker label="AIR DATE" v-model:modelValue="form.assets[0].extraFields.airDate" id="workForm_airDate" @update:modelValue="changeHandler"/>
          </section>
          <h2 class="subTitle">CREDITS</h2>
          <inputGeneric label="MUSIC COMPOSER" v-model:modelValue="form.assets[0].extraFields.musicComposer" id="workForm_musicComposer"/>
          <inputGeneric label="MUSIC TITLE" v-model:modelValue="form.assets[0].extraFields.musicTitle" id="workForm_musicTitle"/>
          <section class="twoColumnsContainer">
            <inputGeneric label="MUSIC PRODUCER" v-model:modelValue="form.assets[0].extraFields.musicProducer" id="workForm_musicProducer"/>
            <inputGeneric label="MUSIC PRODUCTION COMPANY" v-model:modelValue="form.assets[0].extraFields.musicProductionCompany" id="workForm_musicProductionCompany"/>
            <inputGeneric label="PRODUCTION COMPANY PRODUCER" v-model:modelValue="form.assets[0].extraFields.productionCompanyProducer" id="workForm_productionCompanyProducer"/>
            <inputGeneric label="PRODUCTION COMPANY" v-model:modelValue="form.assets[0].extraFields.productionCompany" id="workForm_productionCompany"/>
            <inputGeneric label="POST PRODUCTION COMPANY" v-model:modelValue="form.assets[0].extraFields.postProductionCompany" id="workForm_postProductionCompany"/>
            <inputGeneric label="POST PRODUCTION PRODUCER" v-model:modelValue="form.assets[0].extraFields.postProductionProducer" id="workForm_postProductionProducer"/>
            <inputGeneric label="ANIMATION COMPANY" v-model:modelValue="form.assets[0].extraFields.animationCompany" id="workForm_animationCompany"/>
            <inputGeneric label="ART DIRECTOR" v-model:modelValue="form.assets[0].extraFields.artDirector" id="workForm_artDirector"/>
            <inputGeneric label="ANIMATOR" v-model:modelValue="form.assets[0].extraFields.animator" id="workForm_animator"/>
            <inputGeneric label="AGENCY ASSOCIATE PRODUCER" v-model:modelValue="form.assets[0].extraFields.agencyAssociateProducer" id="workForm_agencyAssociateProducer"/>
            <inputGeneric label="AGENCY ASSISTANT PRODUCER" v-model:modelValue="form.assets[0].extraFields.agencyAssistantProducer" id="workForm_agencyAssistantProducer"/>
            <inputGeneric label="BUSINESS MANAGER" v-model:modelValue="form.assets[0].extraFields.businessManager" id="workForm_businessManager"/>
            <inputGeneric label="CASTING COMPANY" v-model:modelValue="form.assets[0].extraFields.castingCompany" id="workForm_castingCompany"/>
            <inputGeneric label="CINEMATOGRAPHER" v-model:modelValue="form.assets[0].extraFields.cinematographer" id="workForm_cinematographer"/>
            <inputGeneric label="COPYWRITER" v-model:modelValue="form.assets[0].extraFields.copywriter" id="workForm_copywriter"/>
            <inputGeneric label="CREATIVE DIRECTORS" v-model:modelValue="form.assets[0].extraFields.creativeDirectors" id="workForm_creativeDirectors"/>
            <inputGeneric label="DIRECTOR" v-model:modelValue="form.assets[0].extraFields.director" id="workForm_director"/>
            <inputGeneric label="EDITOR" v-model:modelValue="form.assets[0].extraFields.editor" id="workForm_editor"/>
            <inputGeneric label="EXECUTIVE PRODUCER" v-model:modelValue="form.assets[0].extraFields.executiveProducer" id="workForm_executiveProducer"/>
            <inputGeneric label="LINE PRODUCER" v-model:modelValue="form.assets[0].extraFields.lineProducer" id="workForm_lineProducer"/>
          </section>
        </section>
      </collapsibleSection>
      <collapsibleSection title="WEB OPTIONAL METADATA" class="containerWorkForm__containerAdvancedMetadata" v-if="form.assets[0].assetType==='Web'">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputGeneric label="MASTER FILE NAME" v-model:modelValue="form.assets[0].extraFields.masterFileName" id="workForm_masterFileName"/>
          <section class="twoColumnsContainer">
            <inputList label="DIGITAL TYPE" :options="store.state.form.workMetadata.digitalType" v-model:modelValue="form.assets[0].extraFields.digitalType" id="workForm_digitalType"/>
            <inputList label="MASTER FILE FORMAT" :options="store.state.form.workMetadata.masterFileFormat" v-model:modelValue="form.assets[0].extraFields.masterFileFormat" id="workForm_masterFileFormat"/>
            <inputGeneric label="PROGRAMMING VERSIONS" v-model:modelValue="form.assets[0].extraFields.programmingVersions" id="workForm_programmingVersions"/>
            <inputGeneric label="DIGITAL PRODUCERS" v-model:modelValue="form.assets[0].extraFields.digitalProducers" id="workForm_digitalProducers"/>
            <inputGeneric label="DEVELOPER" v-model:modelValue="form.assets[0].extraFields.developer" id="workForm_developer"/>
            <datePicker label="LIVE DATE" v-model:modelValue="form.assets[0].extraFields.liveDate" id="workForm_liveDate" @update:modelValue="changeHandler"/>
            <inputGeneric label="PHOTOGRAPHY" v-model:modelValue="form.assets[0].extraFields.photography" id="workForm_photography"/>
            <inputGeneric label="PHOTOGRAPHER" v-model:modelValue="form.assets[0].extraFields.photographer" id="workForm_photographer"/>
            <inputGeneric label="VENDOR" v-model:modelValue="form.assets[0].extraFields.vendor" id="workForm_vendor"/>
          </section>
        </section>
      </collapsibleSection>
      <collapsibleSection title="SLIDE OPTIONAL METADATA" class="containerWorkForm__containerAdvancedMetadata" v-if="form.assets[0].assetType==='Presentation'">
        <section class="containerWorkForm__containerAdvancedMetadata--containerMain">
          <inputGeneric label="KEYWORDS" v-model:modelValue="form.assets[0].extraFields.slideKeywords" id="workForm_slideKeywords"/>
        </section>
      </collapsibleSection>
    </template>
  </section>
</template>

<script>
import inputList from '@/components/forms/inputList/inputList'
import inputGeneric from '@/components/forms/inputGeneric/inputGeneric'
import workExtraImageUploader from '@/components/forms/workExtraImageUploader/workExtraImageUploader'
import inputListMultipleValues from '@/components/forms/inputListMultipleValues/inputListMultipleValues'
import collapsibleSection from '@/components/forms/collapsibleSection/collapsibleSection'
import datePicker from '@/components/forms/datePicker/datePicker'
import workAwardInput from '@/components/forms/workAwardInput/workAwardInput'
import hiveTooltip from '@/components/hiveTooltip/hiveTooltip'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import { useStore } from 'vuex'
import { inject, ref } from 'vue'

export default {
  name: 'workForm',
  components: {
    inputList,
    inputGeneric,
    workExtraImageUploader,
    collapsibleSection,
    inputListMultipleValues,
    datePicker,
    workAwardInput,
    hiveTooltip,
    genericCheckBox
  },
  props: {
    showErrors: {
      type: Boolean,
      default: false
    },
    fileId: {
      type: String,
      default: null
    }
  },
  emits: ['openAwardModal'],
  setup (props, { emit }) {
    const store = useStore()
    const form = inject('form')
    const workFormRef = ref(null)

    /**
     * @description Searches people based on search term.
     * @param {searchTerm} searchTerm Term to search people.
     */
    function searchPeople (searchTerm) {
      return store.dispatch({
        type: 'form/searchUser',
        searchTerm: searchTerm
      })
    }

    /**
     * @description Searches the tags based on a search term.
     * @param {searchTerm} searchTerm Term to search tags.
     */
    function searchTags (searchTerm) {
      return store.dispatch({
        type: 'form/searchTag',
        searchTerm: searchTerm
      })
    }

    /**
     * @description Makes a search using the API to list coincidences of external users.
     * @param {searchTerm} searchTerm term to search collaborators.
     */
    function searchExternalPeople (searchTerm) {
      return store.dispatch({
        type: 'form/searchExternalUser',
        searchTerm: searchTerm
      }).then((people) => {
        if (people && Array.isArray(people)) {
          return people.map(person => ({ id: person, value: person }))
        }
      })
    }

    /**
     * @description Emits an event to notify the component has changed.
     */
    function changeHandler () {
      workFormRef.value.dispatchEvent(new Event('change', { bubbles: true }))
    }

    /**
     * @description Emit to open award modal
     */
    function openAwardModal () {
      emit('openAwardModal')
    }

    return {
      store,
      searchPeople,
      searchTags,
      searchExternalPeople,
      changeHandler,
      workFormRef,
      form,
      openAwardModal
    }
  }
}
</script>

<template>
  <section class="containerSelectThumbnail">
    <span class="containerSelectThumbnail__title">SELECT THUMBNAIL</span>
    <section class="containerSelectThumbnail__radioButtonCustomAuto">
      <label class="radioButtons__radio">
        <input type="radio" value="custom" v-model="store.state.form.thumbnailSelected.selectionType" />
        <span class="radioButtons__radio--label">Custom</span>
      </label>
      <label class="radioButtons__radio">
        <input type="radio" value="autoGenerated" v-model="store.state.form.thumbnailSelected.selectionType" />
        <span class="radioButtons__radio--label">Auto-generated</span>
      </label>
      <label class="radioButtons__radio">
        <input type="radio" value="upload" v-model="store.state.form.thumbnailSelected.selectionType" />
        <span class="radioButtons__radio--label">Upload</span>
      </label>
    </section>
    <template v-if="store.state.form.thumbnailSelected.selectionType === 'custom'">
      <div class="containerSelectThumbnail__captureHeader">
        <span class="containerSelectThumbnail__thumbnailInfo">Users will see an image that represents your video.
          Once upload is complete you will be able to click the button and select a thumbnail depending on playback.
        </span>
        <button
          class="containerSelectThumbnail__captureHeader--btnThumbnail"
          :class="{'btnThumbnailDisabled': !store.state.form.selectThumbnailInfo.fileProcessed}"
          @click.stop.prevent="captureThumbnail">Thumbnail</button>
      </div>
      <section class="containerSelectThumbnail__captureThumbnail" :style="captureThumbnailStyle">
        <section v-if="!store.state.form.selectThumbnailInfo.fileProcessed" class="containerSelectThumbnail__captureThumbnail--fileProcessing">
          <div class="processingVideo grey-icon-video">
            <span class="processingVideo__processingLabel">Processing video...</span>
          </div>
        </section>
        <section v-else class="containerSelectThumbnail__captureThumbnail--videoPlayer">
          <video
            ref="playerVideoRef"
            controls
            class="thumbnailSelectorPlayer"
            crossorigin="anonymous"
          >
            <source :src="store.state.form.selectThumbnailInfo.fileInformation.permanentSasUrl">
          </video>
        </section>
        <section class="containerSelectThumbnail__captureThumbnail--picture" :class="{'thumbnailSelected': thumbnailCaptured || store.state.form.thumbnailSelected.selections[0]}">
          <div
            class="waterMark"
            v-if="!thumbnailCaptured && !store.state.form.thumbnailSelected.selections[0]"
          >
            <span class="waterMark__label">Click the button to choose your thumbnail</span>
          </div>
          <img
            class="containerSelectThumbnail__picture--img"
            :src="store.state.form.thumbnailSelected.selections[0]"
            v-else-if="!thumbnailCaptured && store.state.form.thumbnailSelected.selections[0]"
          >
          <canvas
            ref="canvasRef"
            class="canvas"
            v-else
          >
          </canvas>
        </section>
      </section>
    </template>
    <template v-if="store.state.form.thumbnailSelected.selectionType === 'autoGenerated'">
      <span class="containerSelectThumbnail__thumbnailInfo">Users will see an image that represents your video.
        Once upload is complete you will be able to choose one of the pre-selected images.
      </span>
      <section v-if="store.state.form.selectThumbnailInfo.fileProcessed" class="containerSelectThumbnail__selectedImages">
        <label
          class="containerSelectThumbnail__selectedImages--radioImg"
          v-for="(item, index) in autoGeneratedThumbnails"
          :key="index"
        >
          <input type="radio" class="inputRadio" :value="item" v-model="radioButtonSelectImage" />
          <div class="image">
            <img class="image__background" :src="item" />
          </div>
        </label>
      </section>
      <section v-else class="containerSelectThumbnail__selectedImagesUploading">
        <div class="containerSelectThumbnail__selectedImagesUploading--first">
          <div class="firstSelection">
          </div>
        </div>
        <div class="containerSelectThumbnail__selectedImagesUploading--next" v-for="index in 2" :key="index">
        </div>
      </section>
    </template>
    <template v-if="store.state.form.thumbnailSelected.selectionType === 'upload'">
      <span class="containerSelectThumbnail__thumbnailInfo">
        Upload thumbnail that reflects your publication content in the application. Remember that only .png and .jpg format are allowed.
      </span>
      <section class="containerSelectThumbnail__thumbnailUploader">
        <thumbnailUploader
          class="containerSelectThumbnail__thumbnailUploader--uploaderContainer"
          :itemId="itemId"
          :thumbnailUrl="store.state.form.thumbnailSelected.selections[2]"
          :source="source"
          @setValidationActive="setValidationActive"
          @resetThumbnailUrl="resetThumbnailUrl">
        </thumbnailUploader>
        <div class="containerSelectThumbnail__thumbnailUploader--message">
          <span class="attention"> This will call the user's attention.</span>
          <p class="validation" :class="{'validationActive': validationActive}">Minimum resolution: 800px x 800px</p>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import { ref, watch, onMounted, computed, nextTick } from 'vue'
import thumbnailUploader from '@/components/forms/thumbnailUploader/thumbnailUploader'
import { useStore } from 'vuex'
import * as SockJS from 'sockjs-client'
import * as Stomp from 'stompjs'
import { openConnectionWs, startConnection } from '@/utils/webSocket'

export default {
  name: 'selectThumbnail',
  props: {
    itemId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  components: {
    thumbnailUploader
  },
  emits: ['touchForm'],
  setup (props, { emit }) {
    const store = useStore()
    const radioButtonSelectImage = ref(null)
    const thumbnailCaptured = ref(null)
    const canvasRef = ref(null)
    const validationActive = ref(false)
    const playerVideoRef = ref(null)
    const stompClient = ref(null)
    const waterMarkWidth = ref('314px')
    const waterMarkHeight = ref('207px')
    const workWebSocket = ref(null)

    /**
     * @description Get list of auto generate thumbnails
     */
    const autoGeneratedThumbnails = computed(() => {
      return store.state.form.selectThumbnailInfo.fileInformation.thumbnails.slice(0, 3)
    })

    /**
     * @description Set dynamic styles for capture thumbnail section
     */
    const captureThumbnailStyle = computed(() => {
      return `--waterMarkWidth: ${waterMarkWidth.value}; --waterMarkHeight: ${waterMarkHeight.value}`
    })

    onMounted(() => {
      if (!store.state.form.selectThumbnailInfo.fileProcessed) {
        if (props.source === 'intelligence') connectIntellWS()
        else connectWorkWS()
      }
      if (store.state.form.thumbnailSelected.selections[1]) {
        radioButtonSelectImage.value = store.state.form.thumbnailSelected.selections[1]
      }
      if (store.state.form.thumbnailSelected.selectionType === 'custom') setWaterMarkSize()
    })

    /**
      * @description Get changes v-model radioButtonCustomAuto.
      * @param value model custom - autoGenerated option selected.
      */
    watch(() => store.state.form.thumbnailSelected.selectionType, (value) => {
      if (value !== 'custom') {
        thumbnailCaptured.value = false
      } else {
        setWaterMarkSize()
      }
    })

    /**
      * @description Get changes v-model radioButtonSelectImage.
      * @param value model autoGenerated Thumbnail selected.
      */
    watch(() => radioButtonSelectImage.value, (value) => {
      if (value) {
        store.dispatch({
          type: 'form/saveDefaultThumbnail',
          itemId: props.itemId,
          thumbnailUrl: value,
          source: props.source
        })
        emit('touchForm')
      }
    })

    /**
     * @description Stablish intelligence web socket connection
     */
    function connectIntellWS () {
      const socket = new SockJS(`${process.env.VUE_APP_INTEL_API}intelligence-stomp`)
      stompClient.value = Stomp.over(socket)
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
      }
      stompClient.value.connect(headers, function (frame) {
        stompClient.value.subscribe(`/topic/getContent.${props.itemId}`, function (content) {
          store.commit({
            type: 'form/SET_SELECT_THUMBNAIL_INFO',
            fileProcessed: true,
            fileInformation: JSON.parse(content.body)
          })
          if (stompClient.value) {
            stompClient.value.disconnect()
          }
          setWaterMarkSize()
        })
      })
    }

    /**
     * @description Stablish works web socket connection
     */
    function connectWorkWS () {
      workWebSocket.value = openConnectionWs(`${process.env.VUE_APP_WORK_SERVICE}notificationshub?workItem_id=${props.itemId}`)
      startConnection(workWebSocket.value)
      workWebSocket.value.on(
        'notify',
        (message) => {
          if (message.notificationType === 'videoProcessingCompleted') {
            store.commit({
              type: 'form/SET_SELECT_THUMBNAIL_INFO',
              fileProcessed: true,
              fileInformation: message.data
            })
            workWebSocket.value.stop()
            setWaterMarkSize()
          }
        }
      )
    }

    /**
     * @description Sets the size of water mark container based on video player size
     */
    function setWaterMarkSize () {
      nextTick(() => {
        const videoTag = document.getElementsByClassName('thumbnailSelectorPlayer')[0]
        if (videoTag) {
          videoTag.addEventListener('loadeddata', function () {
            waterMarkWidth.value = `${playerVideoRef.value.clientWidth}px`
            waterMarkHeight.value = `${playerVideoRef.value.clientHeight}px`
          }, false)
        }
      })
    }

    /**
     * @description Instance canvas technologie and capture image from video player
     */
    function captureThumbnail () {
      thumbnailCaptured.value = true
      setTimeout(() => {
        const context = canvasRef.value.getContext('2d')
        canvasRef.value.width = playerVideoRef.value.clientWidth
        canvasRef.value.height = playerVideoRef.value.clientHeight
        context.fillRect(0, 0, playerVideoRef.value.clientWidth, playerVideoRef.value.clientHeight)
        context.drawImage(playerVideoRef.value, 0, 0, playerVideoRef.value.clientWidth, playerVideoRef.value.clientHeight)
        context.restore()
        canvasRef.value.toBlob((blob) => {
          const file = new File([blob], 'defaultThumbnail.png', { type: 'image/png' })
          if (props.source === 'intelligence') {
            store.dispatch({
              type: 'form/saveCapturedThumbnail',
              itemId: props.itemId,
              capturedThumbnail: file
            })
          } else {
            store.dispatch({
              type: 'form/uploadThumbnail',
              itemId: props.itemId,
              file: file,
              source: props.source,
              origin: 'custom'
            })
          }
          emit('touchForm')
        }, 'image/png')
      })
    }

    /**
     * @description set validation active flag
     * @param flag boolean flag
     */
    function setValidationActive (flag) {
      validationActive.value = flag
    }

    /**
     * @description reset thumbnailUrl
     */
    function resetThumbnailUrl () {
      store.commit({
        type: 'form/SET_THUMBNAIL_SELECTED',
        selectionType: 'upload',
        selectedImage: ''
      })
      emit('touchForm')
    }

    return {
      store,
      thumbnailCaptured,
      canvasRef,
      captureThumbnail,
      radioButtonSelectImage,
      validationActive,
      setValidationActive,
      resetThumbnailUrl,
      playerVideoRef,
      stompClient,
      waterMarkWidth,
      waterMarkHeight,
      captureThumbnailStyle,
      autoGeneratedThumbnails
    }
  }
}
</script>

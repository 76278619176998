<template>
  <genericModalLogic :title="title" class="containerAwardModal" @closeModal="close" :class="{'addModalScroll': addModalScroll}">
    <section class="containerAwardModal__award">
      <inputList label="SHOW" v-model="award.show" :options="store.state.form.workMetadata.awards"/>
      <inputList label="NAME" v-model="award.name" :options="award.show && award.show.children ? award.show.children : []"/>
      <inputGeneric label="CATEGORY" v-model="award.category"/>
      <inputList
        label="YEAR"
        v-model="award.year"
        :options="store.state.form.workMetadata.awardYears"
        ref="yearInputRef"
        @focusin="setModalScroll(true)"
        @focusout="setModalScroll(false)"
        @keydown="handleKeyDown"
        @click="setModalScroll(true)"
        @update:modelValue="setModalScroll(false)"
      />
    </section>
    <section class="containerAwardModal__actions">
      <button @click.stop="close" class="containerAwardModal__actions--btnCancel">CANCEL</button>
      <button @click.stop="confirmAction" class="containerAwardModal__actions--btnConfirm">SAVE</button>
    </section>
  </genericModalLogic>
</template>

<script>
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import inputGeneric from '@/components/forms/inputGeneric/inputGeneric'
import inputList from '@/components/forms/inputList/inputList'
import { ref, onMounted, inject, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'awardModal',
  components: {
    genericModalLogic,
    inputGeneric,
    inputList
  },
  emits: ['changeHandler', 'closeModal'],
  setup (props, { emit }) {
    const store = useStore()
    const awards = inject('form').value.extraFields.awards
    const award = ref({})
    const yearInputRef = ref(null)
    const addModalScroll = ref(false)

    onMounted(() => {
      if (store.state.form.awardIndex !== -1) {
        award.value = { ...awards[store.state.form.awardIndex] }
      } else {
        award.value = { show: null, name: null, category: null, year: null }
      }
    })

    const title = computed(() => {
      return store.state.form.awardIndex !== -1 ? 'Edit Award' : 'Add Award'
    })

    /**
     * @description Closes the modal.
     */
    function close () {
      store.commit({
        type: 'form/SET_AWARD_INDEX',
        awardIndex: -1
      })
      emit('closeModal')
    }

    /**
     * @description Rises an event to notify users wants to cancel the upload process.
     */
    function confirmAction () {
      if (award.value.show || award.value.name || award.value.category || award.value.year) {
        if (store.state.form.awardIndex !== -1) {
          awards[store.state.form.awardIndex] = award.value
        } else {
          awards.push(award.value)
        }
        emit('changeHandler')
      }
      close()
    }

    /**
     * @description Sets modal scroll when year dropdown is open
     * @param addScroll Flag to show or hide scroll
     * @param scrollHeight Number to set the scroll of the container
     */
    function setModalScroll (addScroll, scrollHeight = 170) {
      if (addScroll) {
        const modalContainer = document.querySelector('.containerAwardModal .containerGenericModalLogic__content--body')
        modalContainer.style.setProperty('--modalScrollHeight', `${scrollHeight}px`)
        addModalScroll.value = true
        scrollToBottom(modalContainer)
      } else {
        addModalScroll.value = false
      }
    }

    /**
     * @description Calculates the container height and scroll acording dropdown height
     */
    function handleKeyDown () {
      setTimeout(() => {
        const listLength = yearInputRef.value.optionList.length
        const modalScrollHeight = listLength >= 7 ? 170 : listLength > 2 ? (listLength * 34) - 50 : 28
        setModalScroll(true, modalScrollHeight)
      }, 600)
    }

    /**
     * @description Scrolls modal content to the bottom
     * @param modalContainer The element to scroll
     */
    function scrollToBottom (modalContainer) {
      nextTick(() => {
        modalContainer.scrollTop = modalContainer.scrollHeight
      })
    }

    return {
      store,
      title,
      award,
      close,
      confirmAction,
      yearInputRef,
      handleKeyDown,
      addModalScroll,
      setModalScroll
    }
  }
}
</script>

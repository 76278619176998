<template>
  <section class="containerToastMessage"
    ref="toastMessageRef">
    <span class="containerToastMessage__icon" :class="toastMessageConfig.icon"></span>
    <span class="containerToastMessage__message">
      <span class="containerToastMessage__message--main">{{toastMessageConfig.mainText}}</span>
      <span v-html="formatMessage" />
    </span>
    <span class="grey-icon-close" @click="closeToastMessage"></span>
  </section>
</template>

<script>
import { computed, reactive, onMounted, ref } from 'vue'
import messagesToast from './toastMessage.json'

export default {
  name: 'toastMessage',
  props: {
    message: {
      tpye: String,
      default: ''
    },
    file: {
      type: String,
      default: ''
    },
    autoClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toastHeight', 'closeToast'],
  setup (props, { emit }) {
    const toastMessageRef = ref(null)
    const toastMessageConfig = reactive(messagesToast.toastMessages[props.message])

    /**
     * @description Format the message to show on toast
     */
    const formatMessage = computed(() => {
      return props.message === 'invalidFormat' ? toastMessageConfig.label.replace('${}', props.file) : toastMessageConfig.label
    })

    onMounted(() => {
      if (!props.autoClose) emit('toastHeight', getToastData())
      autoCloseToast()
    })

    /**
     * @description Close toast message
     * @param event DOM event
     */
    function closeToastMessage (event) {
      if (toastMessageRef.value) {
        emit('closeToast', getToastData(event))
      }
    }

    /**
     * @description Close toast automatically after 5 seconds
     */
    function autoCloseToast () {
      const delay = (toastMessageRef.value.dataset.index * 500) + 5000
      if (props.autoClose) {
        setTimeout(() => {
          closeToastMessage()
        }, delay)
      }
    }

    /**
     * @description Returns an object with the toast data
     * @param event Click event data
     */
    function getToastData (event) {
      return {
        height: toastMessageRef.value.offsetHeight,
        file: props.file,
        event
      }
    }

    return {
      toastMessageConfig,
      formatMessage,
      closeToastMessage,
      toastMessageRef
    }
  }
}
</script>

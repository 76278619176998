import { HubConnectionBuilder } from '@aspnet/signalr'

/**
 *@description Request to open connection with WebSockets
 *@returns {connection} Object with its methods
 *@param url to point the request
 */
const openConnectionWs = (url) => {
  const connection = new HubConnectionBuilder()
    .withUrl(url, { accessTokenFactory: () => `${localStorage.getItem('sessionToken')}` })
    .build()
  return connection
}

/**
 *@description Start connection with Web Socket
 *@param connection object
 */
const startConnection = (connection) => {
  let startedPromise = null
  function start () {
    startedPromise = connection.start().catch(err => {
      console.error('Failed to connect with hub', err)
      return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
    })
    return startedPromise
  }
  start()
}

export {
  openConnectionWs,
  startConnection
}

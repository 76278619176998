<template>
  <section class="containerDatePicker" ref="pickerRef" :class="{disabled: disabled, error: errorRequiredValue}">
    <label class="containerDatePicker__label" :class="{'required': required, 'disabled': disabled}">{{ label }}</label>
    <section class="containerDatePicker__input" @click.stop.prevent="openPicker">
      <label class="containerDatePicker__input--label" :class="{'placeholder': !dateModel}">{{ dateLabel }}</label>
      <span class="grey-icon-calendar containerDatePicker__input--calendarIcon" :class="{'calendarActive': datePickerOpen}"></span>
    </section>
    <span class="errorMessage" v-appear="errorRequiredValue">This field cannot be empty</span>
    <datepicker v-model="dateModel" weekdayFormat="EEEEE"
                v-click-away="closePicker"
                monthHeadingFormat="MMMM yyyy"
                @update:modelValue="setModelValue($event)"
                ref="datePickerRef"/>
  </section>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'

export default {
  name: 'datePicker',
  components: {
    Datepicker
  },
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showErrors: {
      default: false,
      type: Boolean
    },
    modelValue: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    let inputPicker = null
    const pickerRef = ref(null)
    const datePickerRef = ref(null)
    const dateModel = ref(null)
    const datePickerOpen = ref(false)

    /**
     * @description Returns a flag to indicate if component doesn't have a value and it is required.
     */
    const errorRequiredValue = computed(() => props.showErrors && props.required && !props.disabled && !dateModel.value)

    /**
     * @description Returns a formated value to show in the label.
     */
    const dateLabel = computed(() => dateModel.value ? moment(dateModel.value).format('MM/DD/YYYY') : 'MM/DD/YYYY')

    onMounted(() => {
      setInitialModelValue()
      inputPicker = pickerRef.value.getElementsByTagName('input')[0]
      loadModalFonts()
    })

    /**
     * @description Updates the model with the value passed via v-model.
     */
    watch(() => props.modelValue, () => {
      setInitialModelValue()
    })

    /**
     * @description Set initial value
     */
    function setInitialModelValue () {
      if (props.modelValue) {
        const formatedDateFromString = new Date(Date.parse((props.modelValue)))
        if (isAValidDate(formatedDateFromString)) {
          dateModel.value = formatedDateFromString
        }
      }
    }

    /**
     * @description Loads the fonts used in the modal, to avoid show a re-render when it is opened.
     */
    function loadModalFonts () {
      if (document && document.fonts && document.fonts.load) {
        document.fonts.load('12px Circular Std Book')
        document.fonts.load('12px Roboto Bold')
      }
    }

    /**
     * @description Opens the modal emulating a click on the invisible input.
     */
    function openPicker () {
      inputPicker.click()
      datePickerOpen.value = true
    }

    /**
     * @description Closes the modal.
     */
    function closePicker () {
      datePickerRef.value.renderView()
      datePickerOpen.value = false
    }

    /**
     * @description Set the model value using the selected date.
     * @param {Date} selectedDate date selected by the user.
     */
    function setModelValue (selectedDate) {
      const formatedDate = moment(selectedDate).format('MM/DD/YYYY')
      emit('update:modelValue', formatedDate)
    }

    /**
     * @description Validates if a date is valid or not.
     * @param {Date} date date ot check  if is valid or not.
     */
    function isAValidDate (date) {
      return date instanceof Date && !isNaN(date)
    }

    return {
      pickerRef,
      setModelValue,
      openPicker,
      datePickerRef,
      dateModel,
      errorRequiredValue,
      dateLabel,
      closePicker,
      datePickerOpen
    }
  }
}
</script>

<template>
  <section class="containerUploader" :class="{disabled: disabled, error: isThereAnError, edition: isAnEdition}" >
    <span class="containerUploader__label required">FILE</span>
    <section class="containerUploader__dragArea"
             @dragenter="addHoverClass"
             @dragover="stopEvent"
             @dragleave="removeHoverClass"
             @drop="dropHandler"
             :class="{ active: hoverIsActive}"
             v-if="!fileInfo.status || fileInfo.status === 'failed'">

      <section v-if="isAnEdition" class="containerUploader__dragArea--containerEdition">
        <span class="containerEdition">
          <span class="grey-icon-info"></span>
          <span class="containerEdition__label">This file can't be modified. If it was uploaded by mistake, please delete it and upload another one.</span>
        </span>
        <span class="fileName">Uploaded file name: {{ fileAlreadyuploaded && fileAlreadyuploaded.name}}</span>
      </section>

      <section class="containerUploader__dragArea--main" v-else>
        <section class="containerLabels">
          <span class="grey-icon-upload-cloud"></span>
          <span class="containerLabels__label">Drop or </span>
          <button class="containerLabels__button" @click="openFileSelector" type="button">Select your File...</button>
        </section>
      </section>
      <input type="file"
             @change="inputChanged"
             ref="inputFileRef"
             class="containerUploader__dragArea--input"
             id="containerUpload"/>
    </section>

    <section class="containerUploader__fileInfo" v-else :class="[fileInfo.status]">
      <span class="grey-icon-success"></span>
      <spinner class="containerUploader__spinner" />
      <span class="containerFileName__fileName">{{ fileInfo.name }}</span>
      <progressBar class="containerFileName__progressBar" ref="progressBar" :progressDynamic="fileInfo.progress" :indeterminated="false"/>
      <span class="grey-icon-close" @click="openCancelModal"></span>
      <span class="containerFileName__percentageLabel">{{fileInfo.progress}}% of {{fileInfo.size}}</span>
    </section>

    <invalidExtensionModal ref="invalidExtensionModalRef"/>
    <formModal ref="formModalRef"
               title="Cancel Upload"
               message="Are you sure of this action?"
               confirmText="YES"
               cancelText="CANCEL"
               @confirmAction="cancelUpload"/>
    <span class="containerUploader__footerLabel" v-show="fileInfo.status !== 'completed'"> {{footerMessage}} </span>
  </section>
</template>

<script>
import progressBar from '@/components/progressBar/progressBar'
import spinner from '@/components/spinner/spinner'
import fileUploaderConfig from './fileUploader.json'
import invalidExtensionModal from '@/components/forms/invalidExtensionModal/invalidExtensionModal'
import formModal from '@/components/forms/formModal/formModal'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'fileUploader',
  components: {
    progressBar,
    invalidExtensionModal,
    formModal,
    spinner
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: null
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isAnEdition: {
      type: Boolean,
      default: false
    },
    fileAlreadyuploaded: {
      type: Object,
      default: null
    },
    contentType: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const hoverIsActive = ref(false)
    const config = fileUploaderConfig
    const counterHover = ref(0)
    const file = reactive({
      name: ''
    })
    const tempId = ref(null)
    const status = ref('idle')
    const inputFileRef = ref(null)
    const invalidExtensionModalRef = ref(null)
    const formModalRef = ref(null)

    /**
     * @description Object with all the file information.
     */
    const fileInfo = computed(() => {
      return store.getters['form/getFileById'](tempId.value)
    })

    /**
     * @description Flag to indicate if an error should be shown.
     */
    const isThereAnError = computed(() => {
      return (props.showErrors && (props.required && fileInfo.value.status !== 'completed')) || fileInfo.value.status === 'failed'
    })

    /**
     * @description Message to be shown in the footer.
     */
    const footerMessage = computed(() => {
      let fileFooterMessage = 'Upload a file to continue filling the information.'
      if (fileInfo.value.status === 'failed') {
        fileFooterMessage = 'Upload failed, Please try again.'
      } else if (fileInfo.value.status === 'uploading') {
        fileFooterMessage = 'Wait until the upload is finished.'
      }
      return fileFooterMessage
    })

    /**
     * @description Emits an event with information of the file.
     * @param {file} file file information.
     */
    watch(fileInfo, (file) => {
      emit('updateFileInfo', file)
    })

    /**
     * @description Opens the modal to select a file.
     */
    function openFileSelector () {
      inputFileRef.value.click()
    }

    /**
     * @description Adds class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function addHoverClass (event) {
      toggleHoverClass(event, true)
    }

    /**
     * @description Removes class with stiles of hover on drag and drop area.
     * @param event event fired by user.
     */
    function removeHoverClass (event) {
      toggleHoverClass(event, false)
    }

    /**
     * @description Toggles precence of hover class to element based on user interaction.
     * @param event event fired by user.
     * @param isClassPresent flag to indicate if hover class should be applied or not.
     */
    function toggleHoverClass (event, isClassPresent) {
      stopEvent(event)
      if (props.disabled) return

      if (isClassPresent) {
        hoverIsActive.value = true
        counterHover.value++
      } else {
        counterHover.value--
        if (counterHover.value === 0) {
          hoverIsActive.value = false
        }
      }
    }

    /**
     * @description Stops propagation of a given event.
     * @param {event} event to stop its propagation.
     */
    function stopEvent (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * @description Manages the drop event to get the file and start to upload process.
     * @param {event} event launches by user's drop action.
     */
    function dropHandler (event) {
      stopEvent(event)

      if (props.disabled) return

      hoverIsActive.value = false

      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        setFile(event.dataTransfer.files[0])
      }
    }

    /**
     * @description Handler to read the file from the input.
     */
    function inputChanged () {
      setFile(inputFileRef.value.files[0])
    }

    /**
     * @description Sets the file if is valid.
     */
    function setFile (file) {
      if (!isAValidExtension(file.name)) {
        cleanFile()
        invalidExtensionModalRef.value.open()
        return
      }

      tempId.value = Math.random()

      store.dispatch({
        type: props.source === 'work' ? 'form/uploadWorkFile' : 'form/uploadIntelFile',
        file: file,
        tempId: tempId.value
      })

      emit('temIdFileCreated', tempId.value)
    }

    /**
     * @description Validates if a file has a valid extension to be uploaded.
     * @param {fileName} fileName to validate its extesion.
     */
    function isAValidExtension (fileName) {
      const fileNameSplitted = fileName.split('.')
      const extension = fileNameSplitted[fileNameSplitted.length - 1].toLowerCase()
      if (props.contentType.value === '5Things') {
        return `.${extension}` === '.pdf'
      } else {
        return config.validExtensions.split(',').some((ext) => ext.toLowerCase() === `.${extension}`)
      }
    }

    /**
     * @description Cleans the file to allow upload a new file.
     */
    function cleanFile () {
      file.value = null
      nextTick(() => {
        inputFileRef.value.value = ''
      })
    }

    /**
     * @description Opens the modal to cancel the process to upload the file.
     */
    function openCancelModal () {
      formModalRef.value.open()
    }

    /**
     * @descriptions Cancels the current upload process.
     */
    function cancelUpload () {
      store.dispatch({
        type: props.source === 'work' ? 'form/removeWorkFile' : 'form/cancelIntelFile',
        tempId: tempId.value
      }).then(() => {
        cleanFile()
      })
    }

    return {
      hoverIsActive,
      config,
      counterHover,
      file,
      tempId,
      status,
      fileInfo,
      isThereAnError,
      footerMessage,
      inputFileRef,
      openFileSelector,
      addHoverClass,
      removeHoverClass,
      toggleHoverClass,
      stopEvent,
      dropHandler,
      inputChanged,
      setFile,
      isAValidExtension,
      cleanFile,
      openCancelModal,
      cancelUpload,
      formModalRef,
      invalidExtensionModalRef
    }
  }
}
</script>

<template>
  <section class="containerInputListMultiple" ref="containerInputListMultiple" :class="{'error': isRequiredErrorVisible}">
    <label class="containerInputListMultiple__label" :class="{'required': required, 'disabled': disabled}">{{ label }}</label>
    <section class="containerInputListMultiple__containerInput">
      <genericPill v-for="(option, index) in infoPills" :key="index" :label="option.value" @clearPill="removeOption(option)"/>
      <genericAutoComplete :placeholder="placeholder"
                           :modelValue="inputValue"
                           :optionList="optionList"
                           @launchSearch="filterList"
                           :specialKeysToDetect="allowUserValues && ['Tab']"
                           :allowUserValues="allowUserValues"
                           ref="autocomplete"
                           :id="id"
                           class="containerInputListMultiple__containerInput--input"
                           :class="{'disabled': disabled}"
                           @update:modelValue="selectOption($event)"
                           @deleteOption="removeOption()"
                           :clearOnSelection="true"
                           :specialCharListToDetect="allowUserValues && [',', ';', ' ']"
                           :lenghtToLaunchSearch="lenghtToLaunchSearch">
        <span class="material-icons containerInputListMultiple__containerInput--arrow" @mousedown="showOptions($event)" v-if="showArrowDropDown">arrow_drop_down</span>
      </genericAutoComplete>
    </section>
    <span class="errorMessage" v-appear="isRequiredErrorVisible">This field cannot be empty</span>
  </section>
</template>

<script>
import { computed, ref, watch } from 'vue'
import genericAutoComplete from '@/components/autoComplete/genericAutoComplete'
import genericPill from '@/components/genericPill/genericPill'

export default {
  name: 'inputListMultipleValues',
  components: {
    genericAutoComplete,
    genericPill
  },
  props: {
    placeholder: {
      type: String,
      default: 'Start typing...'
    },
    options: {
      type: Array,
      default: () => []
    },
    lenghtToLaunchSearch: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    getAsyncOptions: {
      type: Function,
      default: null
    },
    showLabelFunc: {
      type: Function,
      default: null
    },
    idProperty: {
      type: String,
      required: true
    },
    id: {
      type: String
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    allowUserValues: {
      type: Boolean,
      default: false
    },
    showArrowDropDown: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    let model = props.modelValue.map(option => Object.assign({}, option))
    const inputValue = ref(null)
    const optionList = ref(props.options)
    const listReference = ref(null)
    const autocomplete = ref(null)
    const containerInputListMultiple = ref(null)

    /**
     * @description Returns a boolean to indicate that this is a required field and it has not a valid value.
     */
    const isRequiredErrorVisible = computed(() => {
      return props.required && props.showErrors && !props.modelValue.length
    })

    /**
     * @description Returns a formated array to paint the pills.
     */
    const infoPills = computed(() => {
      return props.modelValue.map(option => props.showLabelFunc(option))
    })

    /**
     * @description Detect changes on model value prop
     */
    watch(() => props.modelValue, (newValue, oldValue) => {
      if (!model || newValue.length !== model.length) {
        model = props.modelValue.map(option => Object.assign({}, option))
      }
    })

    /**
     * @description Filters the list of options using the given term as filter.
     * @param {term} string to be used as filter.
     */
    async function filterList (term) {
      clearTemporalInfo()
      let optionsToFill = []
      if (props.getAsyncOptions && term && term.length >= 3) {
        optionsToFill = await props.getAsyncOptions(term)
      } else {
        optionsToFill = props.options.map(option => {
          return { ...option }
        }).filter(option => option.value.toLowerCase().indexOf(term ? term.toLowerCase() : '') >= 0)
      }

      if (optionsToFill.length) {
        fillList(optionsToFill)
      }
    }

    /**
     * @description Fills the list of options based on options info, only add unselected values.
     * @param {options} array with options.
     */
    function fillList (options) {
      const valuesAdded = props.modelValue.map(optionSelected => optionSelected[props.idProperty])

      options.forEach(option => {
        const optionIsAlreadySelected = valuesAdded.some(selectedOption => selectedOption === option[props.idProperty])
        if (!optionIsAlreadySelected) {
          optionList.value.push(props.showLabelFunc(option))
          listReference.value[option[props.idProperty]] = option
        }
      })
    }

    /**
     * @description Updates the model using the selected option.
     * @param {option} object selected by the user.
     */
    function selectOption (option) {
      if (option) {
        let optionSelected = listReference.value[option.id]
        if (!optionSelected && props.allowUserValues) {
          optionSelected = option || optionSelected
          if (model.some(options => options[props.idProperty] === optionSelected[props.idProperty])) {
            return
          }
        }
        updateModel(optionSelected)
      }
    }

    /**
     * @description Emits an event to update the model.
     * @param {option} object with the option selected.
     */
    function updateModel (option) {
      if (option) {
        model.push(Object.assign({}, option))
        model = model.map(optionSelected => Object.assign({}, optionSelected))
        emit('update:modelValue', model)
      }
      clearTemporalInfo()
    }

    /**
     * @description Removes an option from selected options.
     * @param {option} object to be removed from selected options.
     */
    function removeOption (option) {
      if (option) {
        model = model.filter(optionSelected => optionSelected[props.idProperty] !== option.id)
      } else {
        if (model.length) {
          model.pop()
        }
      }
      emit('update:modelValue', model)
      containerInputListMultiple.value.dispatchEvent(new Event('change', { bubbles: true }))
    }

    /**
     * @description Cleans the temporal variables.
     */
    function clearTemporalInfo () {
      optionList.value = []
      listReference.value = {}
    }

    /**
     * @description Opens the list to show the options.
     * @param {event} event rised by the user.
     */
    function showOptions (event) {
      event.preventDefault()
      autocomplete.value.setFocusOnInput()
    }

    return {
      model,
      inputValue,
      optionList,
      listReference,
      autocomplete,
      containerInputListMultiple,
      isRequiredErrorVisible,
      infoPills,
      filterList,
      selectOption,
      removeOption,
      showOptions
    }
  }
}
</script>

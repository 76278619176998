<template>
  <section>
    <genericUploader class="containerExtraImgUploader required"
                    :class="{error: isRequiredErrorVisible}"
                    @fileReady="uploadFile"
                    :allowedExtensions="['.jpg', '.jpeg']"
                    :additionalValidations="isAValidSize"
                    :status="fileInfo.status"
                    :progress="fileInfo.progress"
                    id="form_extraImageWork"
                    @cancelUpload="deleteExtraImage"
                    @deleteFile="openModalConfirmation"
                    :file="modelValue"
                    ref="workExtraImgUploaderRef">
      <template v-slot:label>
        <p class="containerExtraImgUploader__label">
          <label class="containerExtraImgUploader__label--label">WORK HEADER IMAGE</label>
          <label class="containerExtraImgUploader__label--advice">Width: 1920px - Height: 1080px - Fomat: JPG, JPEG - Max file size limit up to 250Kb</label>
        </p>
      </template>
      <template v-slot:footer>
        <section class="containerExtraImgUploader__footer">
          <span class="errorMessage" v-appear="isRequiredErrorVisible">This field cannot be empty</span>
          <span class="errorMessage" v-appear="fileInfo.status==='failed'">Upload failed, please try again.</span>
        </section>
      </template>
    </genericUploader>
    <formModal ref="deleteImageModalRef"
      class="deleteConfirmation"
      title="Delete Work Header Image"
      message="Are you sure you want to delete this image?"
      confirmText="DELETE"
      cancelText="CANCEL"
      deleteConfirmation
      @confirmAction="deleteExtraImage"/>
  </section>
</template>

<script>
import genericUploader from '@/components/forms/genericUploader/genericUploader'
import formModal from '@/components/forms/formModal/formModal'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

export default {
  name: 'workExtraImageUploader',
  components: {
    genericUploader,
    formModal
  },
  props: {
    itemId: {
      type: String,
      default: null
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    modelValue: {}
  },
  setup (props, { emit }) {
    const store = useStore()
    const progress = ref(0)
    const file = ref(null)
    const fileId = ref(null)
    const fileStorageName = ref(null)
    const workExtraImgUploaderRef = ref(null)
    const tempId = ref(null)
    const deleteImageModalRef = ref(null)

    /**
     * @description Gets the information of uploaded file.
     */
    const fileInfo = computed(() => {
      const fileFromStore = store.getters['form/getFileById'](tempId.value)
      if (fileFromStore && Object.keys(fileFromStore).length) {
        return fileFromStore
      } else {
        if (props.modelValue && Object.keys(props.modelValue).length) {
          return props.modelValue
        } else {
          return {}
        }
      }
    })

    /**
     * @description Flag to know if error message about required field should be shown.
     */
    const isRequiredErrorVisible = computed(() => !props.modelValue && props.showErrors)

    /**
     * @description Returns a flag to know if the component is on edition mode.
     */
    const isEdition = computed(() => fileInfo.value && fileInfo.value.status === 'edition')

    /**
     * @description Uploads a file.
     * @param {file} file to be uploaded.
     */
    function uploadFile (file) {
      emit('update:modelValue', file)

      tempId.value = Math.random()
      store.dispatch({
        type: 'form/uploadWorkFile',
        file: file,
        tempId: tempId.value,
        isSupportingFile: true,
        workItemId: props.itemId
      }).then((response) => {
        if (!response) emit('update:modelValue', null)
      })
    }

    /**
     * @description Returns a promise to validate if the file has the specified size.
     * @param {file} file to be validated
     */
    function isAValidSize (file) {
      return new Promise((resolve, reject) => {
        if ((file.size / 1024) > 250) {
          resolve(false)
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = (e) => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => {
              const { width, height } = image
              resolve(width === 1920 && height === 1080)
            }
          }
        }
      })
    }

    /**
     * @description Open delete confirmation modal
     */
    function openModalConfirmation () {
      deleteImageModalRef.value.open()
    }

    /**
     * @description Cleans the file deleting it from the component.
     */
    function deleteExtraImage () {
      emit('update:modelValue', null)
      if (workExtraImgUploaderRef.value.$el) {
        workExtraImgUploaderRef.value.$el.dispatchEvent(new Event('change', { bubbles: true }))
      }
      store.dispatch({
        type: 'form/removeWorkFile',
        tempId: tempId.value,
        workItemId: isEdition.value ? props.modelValue.workItemId : props.itemId,
        isEdition: isEdition.value,
        isSupportingFile: true
      })
    }

    return {
      store,
      progress,
      file,
      fileId,
      fileStorageName,
      workExtraImgUploaderRef,
      fileInfo,
      isRequiredErrorVisible,
      isEdition,
      uploadFile,
      isAValidSize,
      deleteExtraImage,
      deleteImageModalRef,
      openModalConfirmation
    }
  }
}
</script>

<template>
  <section class="containerCollapsibleSection">
    <h2 class="containerCollapsibleSection__title"
        @click.stop="toggleSectionVisibility"
        ref="titleRef"
        :class="{opened: isOpen}">{{title}}
      <span class="grey-icon-arrow-minimize"></span>
    </h2>
    <section v-show="isOpen">
      <slot></slot>
    </section>
  </section>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue'
export default {
  name: 'collapsibleSection',
  props: {
    title: {
      required: true
    }
  },

  setup () {
    const isOpen = ref(false)
    const titleRef = ref(null)

    onMounted(() => {
      isOpen.value = false
    })

    /**
     * @description Toggles the visibility of the collapsible section.
     */
    function toggleSectionVisibility () {
      const isOpenTemp = !isOpen.value

      if (isOpenTemp) {
        isOpen.value = isOpenTemp
        nextTick(() => {
          titleRef.value.scrollIntoView({ behavior: 'smooth', block: 'start' })
        })
      } else {
        titleRef.value.scrollIntoView({ behavior: 'smooth', block: 'end' })
        setTimeout(() => {
          isOpen.value = isOpenTemp
          nextTick(() => {
            document.getElementById('app').scrollTop--
            document.getElementById('app').scrollTop++
          })
        }, 200)
      }
    }

    return {
      isOpen,
      titleRef,
      toggleSectionVisibility
    }
  }
}
</script>

<template>
  <genericModalLogic title="Not Supported File"
                     class="containerInvalidExtensionModal"
                     v-if="isModalVisible"
                     @closeModal="close">
    <p class="containerInvalidExtensionModal__message">
      <span class="containerInvalidExtensionModal__message--message"> {{message}} </span>
      <button @click.stop="close" class="containerInvalidExtensionModal__message--button">OK</button>
    </p>
  </genericModalLogic>
</template>

<script>
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import { ref } from 'vue'

export default {
  name: 'invalidExtensionModal',
  props: {
    message: {
      type: String,
      default: 'Sorry, it looks like the selected file has an extension that we don’t support.'
    }
  },
  components: {
    genericModalLogic
  },
  setup () {
    const isModalVisible = ref(false)

    /**
     * @description Opens the modal.
     */
    function open () {
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param {event} event fired by the user.
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    return {
      isModalVisible,
      open,
      close
    }
  }
}
</script>

<template>
  <div class="containerProgressBar" ref="progressBarRef">
    <span class="containerProgressBar__progress" :class="{completed: progress === 100, empty: progress === 0}"></span>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
export default {
  name: 'progressBar',
  props: {
    initialProgress: {
      type: Number,
      default: 0
    },
    indeterminated: {
      type: Boolean,
      default: true
    },
    timeToFinish: {
      type: Number,
      default: 8
    },
    launchOnMounted: {
      type: Boolean,
      default: false
    },
    progressDynamic: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const progress = ref(null)
    const progressBarRef = ref(null)
    let interval = null

    /**
     * @description Return the maximum progress allowed based on case if progress bar is indeterminated or not.
     */
    const maxAllowedValue = computed(() => props.indeterminated ? 99 : 100)

    onMounted(() => {
      setProgress(props.initialProgress)

      if (props.launchOnMounted) {
        start()
      }
    })

    /**
     * @description Sets a timer interval to refresh progress bar every second.
     */
    function start () {
      interval = setInterval(() => {
        setProgress(progress.value + 1)
        if (progress.value >= maxAllowedValue.value) {
          clearInterval(interval)
        }
      }, (props.timeToFinish * 1000) / (100 - Math.min(props.initialProgress)))
    }

    /**
     * @description Clears the defined interval and set the progress to 100.
     */
    function finish () {
      clearInterval(interval)
      progress.value = 100
      progressBarRef.value.style.setProperty('--progressBarWidth', `${progress.value}%`)
      emit('changeProgress', progress)
    }

    /**
     * @description Sets the value of progress and emits an event to listen those changes.
     */
    function setProgress (progressToSet) {
      progress.value = progressToSet >= maxAllowedValue.value ? maxAllowedValue.value : Math.min(maxAllowedValue.value, progressToSet)
      if (progressBarRef.value) {
        progressBarRef.value.style.setProperty('--progressBarWidth', `${progress.value}%`)
        emit('changeProgress', progress.value)
      }
    }

    /**
     * @description Updates the progress based on a passed prop.
     * @param {Number} progress Progress to be shown in the bar.
     */
    watch(() => props.progressDynamic, (newProgress) => {
      setProgress(newProgress)
    })

    return {
      progressBarRef,
      start,
      finish,
      setProgress,
      progress
    }
  }
}
</script>

<template>
  <section class="containerGenericUploader">
    <slot name="label"></slot>
    <section v-if="status === 'initial' || status === 'failed' || !status"
             class="containerGenericUploader__dropArea"
             ref="dropArea"
             :class="{hoverIsActive: hoverIsActive, failed: status === 'failed'}"
             @dragenter.stop.prevent="toggleHoverClass($event, true)"
             @dragover.stop.prevent=""
             @dragleave.stop.prevent="toggleHoverClass($event, false)"
             @drop.stop.prevent="dropHandler">
      <input type="file" ref="inputFile" class="containerGenericUploader__dropArea--input" :accept="allowedExtensions.join(',')" :id="id" @change="inputChanged($event)">
      <span class="containerGenericUploader__dropArea--labelDrop">Drop or </span>
      <button class="containerGenericUploader__dropArea--buttonSelectFile" @click="openSelectionFile" type="button">Select your File...</button>
      <invalidExtensionModal ref="invalidExtensionModalRef" message="Sorry, it looks like the selected file is not the required format."/>
    </section>
    <section v-if="(status === 'uploading' || status === 'edition' || status === 'completed') && file" class="containerGenericUploader__fileArea">
      <section class="containerGenericUploader__fileArea--labels">

        <template v-if="status === 'uploading'">
          <spinner class="spinner" />
          <span class="fileNameUpload">{{file.name}}</span>
          <button class="cancelButton" type="button" @click.stop.prevent="cancelUpload">Cancel</button>
        </template>

        <template v-if="status === 'edition'">
          <span class="fileName">{{file.originalName}}</span>
          <span class="grey-icon-delete deleteIcon" title="Delete" @click="deleteFile" v-if="enableDelete"></span>
        </template>

        <template v-if="status === 'completed'">
          <span class="grey-icon-success successIcon"></span>
          <span class="fileName">{{file.name}}</span>
          <span class="grey-icon-delete deleteIcon" title="Delete" @click="deleteFile" v-if="enableDelete"></span>
        </template>
      </section>

      <progressBar v-if="status === 'uploading'" class="containerGenericUploader__uploadingArea--progressBar" ref="progressBarRef"/>

      <section class="containerGenericUploader__fileArea--sizeArea" :class="[status]">
        <template v-if="status === 'uploading'">
          <span class="percentageLabel">{{ progress }}% of {{ store.getters.formatSizeFile(file.size) }}</span>
        </template>

        <template v-if="status === 'edition'">
          <span class="size">{{ store.getters.formatSizeFile(file.size) }}</span>
        </template>

        <template v-if="status === 'completed'">
          <span class="size">{{ store.getters.formatSizeFile(file.size) }}</span>
          <span class="successMessage">File uploaded successfully</span>
        </template>
      </section>
    </section>
    <slot name="footer"></slot>
  </section>
</template>

<script>
import { watch, ref } from 'vue'
import { useStore } from 'vuex'
import invalidExtensionModal from '@/components/forms/invalidExtensionModal/invalidExtensionModal'
import progressBar from '@/components/progressBar/progressBar'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'genericUploader',
  components: {
    invalidExtensionModal,
    progressBar,
    spinner
  },
  props: {
    allowedExtensions: {
      type: Array,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    additionalValidations: {
      type: Function,
      default: () => new Promise((resolve) => resolve(true))
    },
    status: {
      type: String,
      default: 'initial'
    },
    progress: {
      type: Number
    },
    enableDelete: {
      type: Boolean,
      default: true
    },
    file: {}
  },
  emits: ['fileReady', 'cancelUpload', 'deleteFile'],
  setup (props, { emit }) {
    const store = useStore()
    const hoverIsActive = ref(false)
    const counterHover = ref(0)
    const dropArea = ref(null)
    const inputFile = ref(null)
    const invalidExtensionModalRef = ref(null)
    const progressBarRef = ref(null)

    /**
     * @description Updates the progress shown in the progress bar.
     */
    watch(() => props.progress, (currentProgress) => {
      if (progressBarRef.value) {
        progressBarRef.value.setProgress(currentProgress)
      }
    })

    /**
     * @description Simulates a click to open th window to select a file.
     */
    function openSelectionFile () {
      inputFile.value.click()
    }

    /**
     * @description Toggles precence of hover class to element based on user interaction.
     * @param event event fired by user.
     * @param isClassPresent flag to indicate if hover class should be applied or not.
     */
    function toggleHoverClass (event, isClassPresent) {
      stopEvent(event)
      if (isClassPresent) {
        hoverIsActive.value = true
        counterHover.value++
      } else {
        counterHover.value--
        if (counterHover.value === 0) {
          hoverIsActive.value = false
        }
      }
    }

    /**
     * @description Stops propagation of a given event.
     * @param {event} event to stop its propagation.
     */
    function stopEvent (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    /**
     * @description Manages the drop event to get the file and start to upload process.
     * @param {event} event launches by user's drop action.
     */
    function dropHandler (event) {
      hoverIsActive.value = false
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        validateFile(event.dataTransfer.files[0])
      }
    }

    /**
     * @description Handler to read the file from the input.
     * @param event DOM event
     */
    function inputChanged (event) {
      validateFile(inputFile.value.files[0])
    }

    /**
     * @description Validates the file to know is is valid according given conditions.
     * @param {file} file to validate.
     */
    function validateFile (file) {
      props.additionalValidations(file).then((isValid) => {
        if (isValid && isAValidExtension(file.name)) {
          emit('fileReady', file)
        } else {
          invalidExtensionModalRef.value.open()
          cleanFile()
        }
      })
    }

    /**
     * @description Validates if a file has a valid extension to be uploaded.
     * @param {fileName} fileName to validate its extesion.
     */
    function isAValidExtension (fileName) {
      const fileNameSplitted = fileName.split('.')
      const extension = fileNameSplitted[fileNameSplitted.length - 1].toLowerCase()
      return props.allowedExtensions.some((ext) => ext.toLowerCase() === `.${extension}`)
    }

    /**
     * @description Cleans the file to allow upload a new file.
     */
    function cleanFile () {
      if (inputFile.value) {
        inputFile.value.value = ''
      }
    }

    /**
     * @description Cancel the upload process.
     */
    function cancelUpload () {
      cleanFile()
      emit('cancelUpload')
    }

    /**
     * @description Deletes the file.
     */
    function deleteFile () {
      cleanFile()
      emit('deleteFile')
    }

    return {
      store,
      hoverIsActive,
      counterHover,
      dropArea,
      inputFile,
      invalidExtensionModalRef,
      progressBarRef,
      openSelectionFile,
      toggleHoverClass,
      dropHandler,
      inputChanged,
      cancelUpload,
      deleteFile
    }
  }
}
</script>

<template>
  <section class="containerWorkAwardInput">
    <div class="containerWorkAwardInput__header">
      <h1 class="containerWorkAwardInput__header--title">AWARDS</h1>
      <button class="containerWorkAwardInput__header--addButton" id="workForm_addAwardButton" @click="addAward" type="button">+ ADD AWARD</button>
    </div>
    <section class="containerWorkAwardInput__award" v-for="(award, index) of awards" :key="index">
      <section class="containerWorkAwardInput__award--info">
        <span class="grey-icon-award awardIcon"/>
        <div class="mainLabel">
          <span class="mainLabel__show">{{showAward(award)}}</span>
          <span class="mainLabel__name">{{nameAward(award)}}</span>
        </div>
        <div class="complementaryLabel">
          <span class="complementaryLabel__category">{{award.category}}</span>
          <span class="complementaryLabel__year">{{yearAward(award)}}</span>
        </div>
      </section>
      <section class="containerWorkAwardInput__award--actions">
        <span class="grey-icon-edit-work" title="Edit" @click="editAward(index)"/>
        <span class="grey-icon-delete" title="Delete" @click="openDeleteModal(index)"/>
      </section>
      <transition name="fade">
        <section class="containerWorkAwardInput__award--deleteModal" v-if="index === deleteIndex" v-click-away="hideDeleteModal">
          <section class="modal">
            <span class="modal__label">Are you sure you want to delete this?</span>
            <button class="modal__confirmButton" @click.stop.prevent="deleteAward(index)">Delete</button>
            <button class="modal__cancelButton" @click.stop.prevent="hideDeleteModal">Cancel</button>
          </section>
        </section>
      </transition>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { ref, inject, computed } from 'vue'

export default {
  name: 'workAwardInput',
  emits: ['changeHandler', 'openAwardModal'],
  setup (props, { emit }) {
    const store = useStore()
    const awards = inject('form').value.extraFields.awards
    const deleteIndex = ref(null)

    /**
     * @description Get show of the award
     */
    const showAward = computed(() => award => {
      return award.show && award.show.value ? award.show.value : award.show
    })

    /**
     * @description Get name of the award
     */
    const nameAward = computed(() => award => {
      return award.name && award.name.value ? award.name.value : award.name
    })

    /**
     * @description Get year of the award
     */
    const yearAward = computed(() => award => {
      return award.year && award.year.value ? award.year.value : award.year
    })

    /**
     * @description Adds a new empty award.
     */
    function addAward () {
      store.commit({
        type: 'form/SET_AWARD_INDEX',
        awardIndex: -1
      })
      emit('openAwardModal')
    }

    /**
     * @description Adds a new empty award.
     * @param index index of the award
     */
    function editAward (index) {
      store.commit({
        type: 'form/SET_AWARD_INDEX',
        awardIndex: index
      })
      if (store.state.form.workMetadata && store.state.form.workMetadata.awards && awards[index].show && !awards[index].show.value) {
        awards[index] = {
          ...awards[index],
          show: store.state.form.workMetadata.awards.find(awardObject => awardObject.value === awards[index].show)
        }
      }
      emit('openAwardModal')
    }

    /**
     * @description Toggles visibility of confirmation modal to delete an award.
     * @param index index of the award.
     */
    function openDeleteModal (index) {
      deleteIndex.value = index
    }

    /**
     * @description Hides the confirmation modal to delete an award.
     */
    function hideDeleteModal () {
      deleteIndex.value = null
    }

    /**
     * @description Remove selected award from the list.
     * @param index index of the award.
     */
    function deleteAward (index) {
      hideDeleteModal()
      awards.splice(index, 1)
      emit('changeHandler')
    }

    return {
      store,
      addAward,
      awards,
      showAward,
      nameAward,
      yearAward,
      deleteIndex,
      openDeleteModal,
      hideDeleteModal,
      deleteAward,
      editAward
    }
  }
}
</script>
